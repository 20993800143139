import { Col, Container, Row } from 'react-bootstrap'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function Privacy() {
    return (
        <InnerPageWrapper>
            <Container>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <h1>
                            Privacy
                            <br />
                            Policy
                        </h1>
                    </Col>
                    <Col xs={12} lg={7} xl={8} className="mt-4 mt-lg-0 ">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ullamcorper malesuada proin
                            libero nunc consequat interdum varius sit amet. Vel
                            pretium lectus quam id. Viverra nam libero justo
                            laoreet. Urna molestie at elementum eu facilisis sed
                            odio morbi. Tristique senectus et netus et malesuada
                            fames ac. Maecenas volutpat blandit aliquam etiam
                            erat velit scelerisque in dictum. Proin nibh nisl
                            condimentum id venenatis a. Fermentum dui faucibus
                            in ornare quam viverra orci sagittis. Est placerat
                            in egestas erat imperdiet sed euismod nisi. Diam
                            maecenas ultricies mi eget mauris. Sit amet cursus
                            sit amet dictum sit amet. Convallis posuere morbi
                            leo urna. Facilisi cras fermentum odio eu feugiat
                            pretium nibh. Natoque penatibus et magnis dis
                            parturient montes.
                        </p>

                        <p>
                            Tincidunt id aliquet risus feugiat in ante metus
                            dictum at. Eu sem integer vitae justo eget magna
                            fermentum iaculis eu. Morbi leo urna molestie at
                            elementum eu facilisis. Pretium fusce id velit ut.
                            Amet luctus venenatis lectus magna fringilla.
                            Tincidunt lobortis feugiat vivamus at augue eget
                            arcu dictum varius. A arcu cursus vitae congue
                            mauris rhoncus aenean vel elit. Tortor dignissim
                            convallis aenean et tortor at. Venenatis urna cursus
                            eget nunc scelerisque viverra mauris in aliquam.
                            Pretium fusce id velit ut.
                        </p>

                        <p>
                            Convallis convallis tellus id interdum velit
                            laoreet. Luctus venenatis lectus magna fringilla.
                            Est ultricies integer quis auctor elit. In nulla
                            posuere sollicitudin aliquam ultrices sagittis orci
                            a scelerisque. Eu ultrices vitae auctor eu augue ut.
                            Quam nulla porttitor massa id neque aliquam
                            vestibulum morbi. Purus sit amet volutpat consequat
                            mauris nunc congue nisi. Eget sit amet tellus cras
                            adipiscing enim eu turpis. Nibh mauris cursus mattis
                            molestie a iaculis. Odio tempor orci dapibus
                            ultrices. Morbi tempus iaculis urna id volutpat
                            lacus. Mattis aliquam faucibus purus in massa
                            tempor. Nunc sed id semper risus in hendrerit
                            gravida rutrum. Lobortis elementum nibh tellus
                            molestie nunc non blandit massa enim. Elementum
                            curabitur vitae nunc sed velit dignissim sodales.
                            Ante in nibh mauris cursus mattis molestie a iaculis
                            at.
                        </p>

                        <p>
                            Hendrerit gravida rutrum quisque non tellus orci ac
                            auctor. Pulvinar mattis nunc sed blandit libero.
                            Commodo ullamcorper a lacus vestibulum sed arcu.
                            Urna id volutpat lacus laoreet non curabitur
                            gravida. Lacus sed turpis tincidunt id aliquet risus
                            feugiat. Sed vulputate odio ut enim blandit volutpat
                            maecenas volutpat. Id diam maecenas ultricies mi
                            eget mauris pharetra et. Amet dictum sit amet justo
                            donec enim diam vulputate. Turpis egestas maecenas
                            pharetra convallis posuere morbi leo. In mollis nunc
                            sed id. Amet cursus sit amet dictum sit amet.
                            Maecenas pharetra convallis posuere morbi leo. Donec
                            ac odio tempor orci dapibus. Sem integer vitae justo
                            eget.
                        </p>

                        <p>
                            Vel pharetra vel turpis nunc eget. Venenatis a
                            condimentum vitae sapien pellentesque habitant
                            morbi. Mi proin sed libero enim sed faucibus. Et
                            malesuada fames ac turpis egestas maecenas. A arcu
                            cursus vitae congue mauris rhoncus aenean. Facilisis
                            leo vel fringilla est ullamcorper eget nulla
                            facilisi etiam. Nibh praesent tristique magna sit
                            amet purus gravida quis. Id neque aliquam vestibulum
                            morbi blandit cursus risus. Nunc vel risus commodo
                            viverra maecenas accumsan. Congue nisi vitae
                            suscipit tellus mauris a diam maecenas. Gravida in
                            fermentum et sollicitudin ac orci phasellus egestas
                            tellus. Iaculis at erat pellentesque adipiscing
                            commodo elit. Eleifend mi in nulla posuere
                            sollicitudin aliquam ultrices sagittis orci. Proin
                            gravida hendrerit lectus a. Integer malesuada nunc
                            vel risus commodo viverra maecenas accumsan. Vitae
                            ultricies leo integer malesuada nunc.
                        </p>

                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ullamcorper malesuada proin
                            libero nunc consequat interdum varius sit amet. Vel
                            pretium lectus quam id. Viverra nam libero justo
                            laoreet. Urna molestie at elementum eu facilisis sed
                            odio morbi. Tristique senectus et netus et malesuada
                            fames ac. Maecenas volutpat blandit aliquam etiam
                            erat velit scelerisque in dictum. Proin nibh nisl
                            condimentum id venenatis a. Fermentum dui faucibus
                            in ornare quam viverra orci sagittis. Est placerat
                            in egestas erat imperdiet sed euismod nisi. Diam
                            maecenas ultricies mi eget mauris. Sit amet cursus
                            sit amet dictum sit amet. Convallis posuere morbi
                            leo urna. Facilisi cras fermentum odio eu feugiat
                            pretium nibh. Natoque penatibus et magnis dis
                            parturient montes.
                        </p>

                        <p>
                            Tincidunt id aliquet risus feugiat in ante metus
                            dictum at. Eu sem integer vitae justo eget magna
                            fermentum iaculis eu. Morbi leo urna molestie at
                            elementum eu facilisis. Pretium fusce id velit ut.
                            Amet luctus venenatis lectus magna fringilla.
                            Tincidunt lobortis feugiat vivamus at augue eget
                            arcu dictum varius. A arcu cursus vitae congue
                            mauris rhoncus aenean vel elit. Tortor dignissim
                            convallis aenean et tortor at. Venenatis urna cursus
                            eget nunc scelerisque viverra mauris in aliquam.
                            Pretium fusce id velit ut.
                        </p>
                    </Col>
                </Row>
            </Container>
        </InnerPageWrapper>
    )
}
