export const Logo = () => {
    return (
        <svg
            width="202"
            height="40"
            viewBox="0 0 202 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M137.024 5.35307C140.402 8.83131 142.447 13.8945 142.447 20.2399C142.447 33.1241 133.372 40 124.228 40C118.969 40 114.264 38.1387 110.873 34.6743C107.482 31.2094 105.423 26.1593 105.423 19.8134C105.423 6.87595 114.498 0 123.695 0C128.955 0 133.647 1.87506 137.024 5.35307ZM133.928 32.3673C136.195 29.7547 137.527 26.0038 137.527 21.4662C137.527 15.958 136.189 11.4729 133.74 8.3703C131.297 5.2748 127.731 3.53261 123.215 3.53261C119.418 3.53261 116.208 5.03571 113.943 7.65894C111.675 10.285 110.343 14.0495 110.343 18.5872C110.343 24.0947 111.694 28.5664 114.149 31.6557C116.599 34.7383 120.165 36.4674 124.655 36.4674C128.452 36.4674 131.663 34.9771 133.928 32.3673Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M175.497 39.5735H167.984C166.717 39.5735 165.551 38.8802 164.947 37.7668L157.432 23.9298L157.431 23.9284C156.821 22.7876 156.299 22.0373 155.556 21.5606C154.814 21.0851 153.818 20.86 152.209 20.86H150.163V30.5297C150.163 34.2707 150.382 37.1295 151.723 39.1921L151.971 39.5735H145.296V9.47031C145.296 5.72656 145.13 2.9238 143.737 0.809108L143.485 0.426526H153.222C155.704 0.426526 159.203 0.571361 162.087 1.83342C163.534 2.4664 164.836 3.38447 165.776 4.71452C166.716 6.04632 167.282 7.77386 167.282 10.0035C167.282 12.5325 166.546 14.7237 165.084 16.4603C163.748 18.0469 161.818 19.2381 159.327 19.9675C161.228 20.8625 162.031 22.3585 162.912 23.9997C162.972 24.1107 163.032 24.2223 163.092 24.3344L167.518 32.4374C167.751 32.8653 167.979 33.269 168.203 33.6524L168.202 33.6496L169.423 35.5179C169.634 35.8398 170.108 35.8047 170.276 35.4739L181.873 4.80088L181.873 4.79938C182.299 3.62911 182.551 2.81189 182.551 1.84628C182.551 1.49377 182.5 1.09026 182.401 0.741164L182.311 0.426526H187.401L197.111 29.0643L197.111 29.0655C197.617 30.5816 198.294 32.4793 199.096 34.2884C199.9 36.1017 200.821 37.8074 201.81 38.9521L201.87 39.0216V39.5735H195.398L195.356 39.3791C195.092 38.1668 194.509 36.2593 193.976 34.6059L193.975 34.6041L191.206 26.3514H177.531L175.723 31.2574L175.722 31.26C175.03 33.0149 174.512 34.7376 174.512 36.1811C174.512 37.2641 174.771 38.339 175.278 39.2015L175.497 39.5735ZM150.163 17.6472V3.74587H152.316C154.581 3.74587 157.104 3.90784 159.056 4.83494C160.027 5.29591 160.849 5.94292 161.43 6.84868C162.011 7.75419 162.361 8.9355 162.361 10.4833C162.361 13.1924 161.441 14.9608 159.848 16.0651C158.237 17.1819 155.898 17.6472 153.009 17.6472H150.163ZM190.134 23.032H178.78L184.759 6.95949L190.134 23.032ZM182.708 0.961587C182.688 0.863288 182.665 0.766655 182.638 0.673354C182.661 0.753523 182.681 0.836151 182.699 0.920182C182.702 0.933949 182.705 0.947749 182.708 0.961587Z"
                fill="currentColor"
            />
            <path
                d="M51.8991 3.8525H47.2397V17.3807H60.0387V22.4685L59.6519 22.2027C58.0101 21.0742 56.1548 20.6467 51.8458 20.6467H47.2397V29.4634C47.2397 32.3163 47.3428 34.4155 48.0134 36.1475H53.2857C58.6724 36.1475 61.1495 35.7694 63.4218 33.9108L63.49 33.855H64.0733L63.3145 39.5735H42.4796V9.47031C42.4796 5.72656 42.3134 2.9238 40.9205 0.809108L40.6684 0.426526H61.5001L62.2617 5.93178H61.7355L61.6695 5.88189C59.3421 4.12388 57.1271 3.8525 51.8991 3.8525Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.65681 5.40159L0.130859 39.5735H5.67015L5.46519 39.2064C4.2229 36.9812 4.10985 34.4859 4.32265 31.1869L4.32275 31.1854L5.89642 10.7841L17.8303 39.5735H19.0627L31.6801 11.023L33.5493 39.5735H39.5302L39.3301 39.2081C38.1349 37.0262 37.9742 34.6331 37.7601 31.1543L35.7182 0.426526H31.5605V0.673354C31.5605 2.11665 30.989 3.94623 30.4611 5.16002L19.2281 30.8063L6.64114 0.426526H2.20658L2.29164 0.738299C2.60422 1.88413 2.8149 3.08259 2.65686 5.40086L2.65681 5.40159ZM2.52984 0.673354C2.76001 1.51711 2.935 2.38845 2.95553 3.6843C2.93698 2.51422 2.79252 1.69025 2.5952 0.920182C2.57399 0.837405 2.55218 0.75525 2.52984 0.673354Z"
                fill="currentColor"
            />
            <path
                d="M65.6575 39.5735L68.1835 5.40159L68.1835 5.40086C68.3416 3.08259 68.1309 1.88413 67.8183 0.738299L67.7332 0.426526H72.1678L84.7548 30.8063L95.9878 5.16002C96.5156 3.94623 97.0872 2.11665 97.0872 0.673354V0.426526H101.245L103.287 31.1543C103.501 34.6331 103.662 37.0262 104.857 39.2081L105.057 39.5735H99.076L97.2067 11.023L84.5893 39.5735H83.3569L71.4231 10.7841L69.8494 31.1854L69.8493 31.1869C69.6365 34.4859 69.7495 36.9812 70.9918 39.2064L71.1968 39.5735H65.6575Z"
                fill="currentColor"
            />
        </svg>
    )
}
