import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './style.module.css'
import Button from 'src/components/button'

const Hero = () => {

    function scrollToAuctions() {
        const container = document.getElementById('auctions-explore');
        console.log("scroll!", container);
        document.getElementById('auctions-explore')?.scrollIntoView();
    }

    return (
        <div className={`${styles.heroSection}`}>
            <Container>
                <Row>
                    <Col>
                        <h1>
                            Memorable items,
                            <br />
                            meaningful impact.
                        </h1>
                        <div className="d-flex justify-content-center mt-5">
                            <Button variant="primary" fullWidth={false} onClick={() => scrollToAuctions() }>
                                Discover →
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
