import { Col, Container, Row } from 'react-bootstrap'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function Terms() {
    return (
        <InnerPageWrapper>
            <Container>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <h1>Terms & Conditions</h1>
                    </Col>
                    <Col xs={12} lg={7} xl={8} className="mt-4 mt-lg-0 ">
                        <p>
                            These Terms and Conditions are a binding legal
                            contract between Etika Inc ("MEMORA") and you. These
                            Terms apply to and govern your use of the MEMORA
                            website located at www.memora-house.com (“Site” or
                            “Platform”) and any of our related services or
                            content (the Site and these services and content are
                            together referred to herein as the “Services”).
                        </p>

                        <p>
                            Please read these terms carefully and do not use
                            this Site or the Services if you do not agree with
                            these Terms of Use. The Services include auctions,
                            for a variety of Asset (hereinafter defined). By
                            using this Site and participating in these auctions,
                            you agree to be bound to any purchases you win, and
                            you further agree to be bound by these terms and
                            conditions. If you do not agree, do not use the
                            Platform.
                        </p>

                        <p>
                            The most recent version of these Terms will always
                            be available on the Platform. MEMORA reserves the
                            right to modify these Terms at any time. Continued
                            use of the Platform after changes indicates your
                            acceptance of the updated Terms.
                        </p>

                        <p>
                            The Services are available only to individuals who
                            can form legally binding contracts under applicable
                            law and for non-commercial purposes in compliance
                            with these Terms of Use. Without limiting the
                            foregoing, our Services are not available to minors
                            under the age of 18, nor persons who are barred from
                            receiving the Services under the laws of the United
                            States or other applicable jurisdictions. You may
                            not use the Services if you do not qualify, and your
                            use of the Services is your representation that you
                            do qualify.
                        </p>

                        <p>
                            Notice regarding dispute resolution: these terms of
                            use contain provisions that govern how claims you
                            and MEMORA have against each other are resolved,
                            including an obligation to arbitrate disputes, which
                            will, subject to limited exceptions, require you to
                            submit claims you have against MEMORA to binding
                            arbitration, unless you opt-out in accordance with
                            the terms hereof. Unless you opt-out of arbitration:
                            (a) you will only be permitted to pursue claims
                            against MEMORA on an individual basis, not as part
                            of any class or representative action or proceeding
                            and (b) you will only be permitted to seek relief
                            (including monetary, injunctive, and declaratory
                            relief) on an individual basis.
                        </p>

                        <h3>1. OPERATION OF THE PLATFORM.</h3>

                        <p>
                            As a condition of using certain features of the
                            Services, you must register through the Site.
                            Registration on the Site requires creation of a user
                            account ("User Account") whereby you will provide
                            your real name, phone number, email address, credit
                            card details, and other requested information, and
                            selection of a password and user I.D. (“User I.D.”)
                            You may not select or use as a User I.D. a name: (a)
                            of another person with the intent to impersonate
                            that person; (b) subject to any rights of a person
                            other than you without appropriate authorization; or
                            (c) that is otherwise offensive, vulgar or obscene.
                            We reserve the right to refuse registration of, or
                            to cancel, a User Account in our sole discretion.
                            You can also log into the Site through a third-party
                            social media service to which you belong, such as
                            Facebook. By logging in through a social media
                            service, you grant us permission to access and use
                            the information that you post or store on the
                            applicable social media service, in accordance with
                            the privacy policy of that service and the privacy
                            settings that are applicable to your account, and to
                            store the user name and password you use to log-in
                            to the applicable social media service. For more
                            details on how you can manage the information
                            provided to us by social media services please
                            review the privacy settings applicable to your
                            account with the applicable social media service.
                            The Site is available only to those who register and
                            meet the terms of eligibility, who hold a current,
                            valid credit card by a bank acceptable to MEMORA,
                            whose User Account requests are accepted by MEMORA,
                            and who have authorized MEMORA to process any and
                            all charges on their credit card or bank account for
                            any Lot (hereinafter defined). As part of the
                            registration process, MEMORA will validate your
                            credit card and/or bank information. MEMORA reserves
                            the right to review, change, or block the "username"
                            of any person registering to participate in any
                            auction on this Site, at any time and for any
                            reason. Your password may be used to access and use
                            the Site and the Services, electronically sign and
                            bind you to your transactions and review your
                            completed transactions. You are solely responsible
                            for maintaining the security of your User Account
                            and password. You may not disclose your password to
                            any third party and you are solely responsible for
                            any use of, or action taken under, your User Account
                            and password. If your password is compromised, you
                            must change your password and immediately notify
                            MEMORA of any unauthorized use or other security
                            breach. MEMORA reserves the right to continue any
                            Auction involving a compromised User Account as if
                            such compromised User Account had never bid on such
                            Auction. MEMORA will not be liable for any loss or
                            damage arising from your failure to comply with
                            these Terms. Once you register as a user (“User”) on
                            the Site, the Site offers the following way to a
                            User to bid on auctions for Assets: by bidding
                            through an online auction (the "Auction") on the
                            Site that is offered by a charitable organization or
                            third party (collectively, the "Seller").  As used
                            herein, a "Asset" is tangible Asset, including
                            without limitation artwork, apparel, food, fashion,
                            and sports and music MEMORAbilia and its digital
                            twins. As used herein, the term "Lot" refers to an
                            Asset offered through an Auction.
                        </p>
                        <p>
                            We offer the Services as a technology web
                            application to allow Sellers to conduct Auctions.
                            For more information on such promotional offers, see
                            the Auctions Section below. Payment, processing and
                            transaction fees will be added on top of a winning
                            bid. All purchases are final and non-refundable. We
                            are constantly evolving and improving the Services
                            and may add or change features and offerings in the
                            future. We may also modify, suspend or discontinue
                            all or some of the Services at any time without
                            notice or liability. Eligible Users are free to bid
                            on an Auction. Use of the Services is intended for
                            individuals who are at least eighteen (18) years of
                            age, unless otherwise indicated. Additional
                            restrictions may apply to any auction and will be
                            disclosed in the specific rules for such auction. If
                            you are not at least eighteen (18) years old, please
                            do not access, visit or use the Site or Services.
                        </p>
                        <h3>2. MEMORA AUCTIONS.</h3>
                        <p>
                            The Services provide a platform for Sellers to offer
                            Lots for Auction, to Users, and for the User with
                            the highest bid at the close of the Auction to
                            purchase the Lot. The ending time for each Auction
                            is stated on the bidding page for each such Lot.
                            Bids will be accepted from the opening of the
                            Auction until the end time only. MEMORA reserves the
                            right, in its sole discretion and without notice, to
                            extend, terminate or modify the Auction in the event
                            of technical difficulties altering the proper
                            functioning of the Site or the Auction.
                        </p>
                    </Col>
                </Row>
            </Container>
        </InnerPageWrapper>
    )
}
