import React from 'react'
import Form from 'react-bootstrap/Form'
import styles from './style.module.css'

import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'
function CheckboxContainer() {
    const { openModal } = useModal()
    const handleOpenModal = () => {
        openModal(MODAL.ACCEPT_TERMS)
    }

    return (
        <Form className={styles.formCheckbox}>
            <div className={styles.checkboxContainer}>
                <Form.Check
                    className={`${styles.checkboxItem} ${styles.customCheckbox}`}
                    reverse
                    label={
                        <span>
                            I have read and agree to the{' '}
                            <span
                                className={styles.linkText}
                                onClick={handleOpenModal}>
                                Terms & Conditions
                            </span>
                        </span>
                    }
                    name="group1"
                    type="checkbox"
                    id="termsCheckbox"
                />
                <hr className={styles.divider} />
                <Form.Check
                    className={`${styles.checkboxItem} ${styles.customCheckbox}`}
                    reverse
                    label={
                        <span>
                            I have read and agree to the{' '}
                            <span
                                className={styles.linkText}
                                onClick={handleOpenModal}>
                                Privacy Policy
                            </span>
                        </span>
                    }
                    name="group1"
                    type="checkbox"
                    id="privacyCheckbox"
                />
            </div>
        </Form>
    )
}

export default CheckboxContainer
