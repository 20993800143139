import styles from './style.module.css'
import BackHeader from 'src/components/bidding/back-header'
import formStyles from '../form.module.css'
import Button from 'src/components/button'
const EditAddress = ({ setState, lot }) => {
    return (
        <div className={styles.container}>
            <BackHeader
                action={() => setState(0)}
                title={'Edit delivery address'}
                lot={lot}
            />

            <div className={formStyles.form}>
                <div className={formStyles.formLabel}>Address</div>
                <input type="text" placeholder="Some address" />
                <div className={formStyles.half}>
                    <input type="text" placeholder="City" />
                    <input type="text" placeholder="Zip code" />
                </div>
                <input type="text" placeholder="Country" />
            </div>
            <div className={formStyles.footer}>
                <div>
                    Please note that updating your address information here will
                    also update it in your profile.
                </div>
                <Button fullWidth={false}>Update address</Button>
            </div>
        </div>
    )
}

export default EditAddress
