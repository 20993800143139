import React from 'react'

// eslint-disable-next-line react/prop-types
const BackIcon = ({ onClick }) => (
    <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}>
        <circle cx="18" cy="18.793" r="18" fill="#D4CFC7" />
        <path
            d="M17.48 11.293L10 18.793M10 18.793L17.48 26.293M10 18.793H26"
            stroke="black"
        />
    </svg>
)

export default BackIcon
