import styles from './style.module.css'

const AuctionImage = ({ image }) => {
    return (
        <div
            className={styles.image}
            style={{
                backgroundImage: `url('${image}')`,
            }}></div>
    )
}

export default AuctionImage
