import styles from './style.module.css'

const LotTags = ({ tags }) => {
    return (
        <div className={styles.tagsContainer}>
            {tags.map((tag, index) => {
                return <span key={index}>{tag}</span>
            })}
        </div>
    )
}

export default LotTags
