import styles from './style.module.css'
import AuctionStatus from 'src/components/auction/auction-status'
import LotTags from '../lot-tags'
import Label from 'src/components/label'
import LotInfoRow from '../lot-info-row'
import sampleVideo from 'src/assets/images/sample-video.mp4'
import { Col, Ratio } from 'react-bootstrap'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'
import { useCallback } from 'react'
import PlayIcon from '../../../assets/images/playIcon'

const LotDescription = ({ lot }) => {
    const { openModal } = useModal()

    const openCelebrity = useCallback(() => {
        openModal(MODAL.CONTENT_MODAL, {
            title: lot?.celebrity?.name,
            description_full: lot?.celebrity?.description_full,
            cover_image: lot?.celebrity?.cover_image,
        })
    }, [lot?.celebrity?.name])

    return (
        <Col md={12} lg={6} className={styles.descriptionWrapper}>
            <LotTags tags={[lot?.celebrity?.name, lot?.['memorabilia']]} />
            <div className={styles.titleContainer}>
                <h1>{lot?.title}</h1>
            </div>

            {/* FIXME: Need common component for auction and lot */}
            <AuctionStatus auction={{ auction: lot }} />

            <div className={styles.content}>
                <div>
                    <Label text={'lot estimate'} />
                    {/* FIXME: should show parametrized currency */}
                    <div>
                        €{lot?.estimate_low} - €{lot?.estimate_high}
                    </div>
                </div>
                <div className={styles.description}>
                    <Label text={'description'} />
                    <div>{lot?.description}</div>
                </div>
                <LotInfoRow
                    label={'Owner'}
                    title={lot?.celebrity?.name}
                    onClick={openCelebrity}
                />
                <LotInfoRow
                    label={'Charity'}
                    title={lot?.foundation?.title}
                    onClick={() =>
                        openModal(MODAL.CONTENT_MODAL, lot.foundation)
                    }
                />
                <LotInfoRow
                    label={'STUB_Digital ID'}
                    title={'Details about this item'}
                    onClick={() => openModal(MODAL.DIGITAL_ID, lot.foundation)}
                />

                {window.location.href.includes('demo_video') && (
                    <div
                        onClick={() => openModal(MODAL.VIDEO, lot.foundation)}
                        className={styles.videoWrapper}
                        style={{
                            backgroundImage: `url(
                                https://dummyimage.com/600x400/000/fff&text=video_thumb
                            )`,
                        }}>
                        <PlayIcon />
                    </div>
                )}
                {lot?.promo_video && (
                    <div className={styles.videoContainer}>
                        <Ratio aspectRatio="16x9">
                            {/* <video src={lot?.promo_video} controls /> */}
                            <iframe
                                src={lot.promo_video}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                        </Ratio>
                    </div>
                )}
            </div>
        </Col>
    )
}

export default LotDescription
