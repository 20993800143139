import React from 'react'
import styles from './style.module.css'

const ProgressBar = ({ filename, percentage }) => {
    return (
        <div className={styles.progressContainer}>
            <div style={{ width: '100%' }}>
                <div>
                    <span className={styles.filename}>{filename}</span>
                </div>

                {percentage < 100 ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={styles.progressBar}>
                            <div
                                className={styles.progressFill}
                                style={{ width: `${percentage}%` }}></div>
                        </div>
                        <span className={styles.percentage}>{percentage}%</span>
                    </div>
                ) : (
                    <div className={styles.fileSize}>30.5 MB</div>
                )}
            </div>

            <div>
                <div className={styles.closeButton}>
                    <img src="/close-icon.svg" alt="Close" />
                </div>
            </div>
        </div>
    )
}

export default ProgressBar
