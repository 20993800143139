import { Link } from 'react-router-dom'
import styles from './style.module.css'

const TabsComponent = ({ tabs, active, style = {} }) => {
    return (
        <div className={`${styles.container}`} style={style} id="auctions-explore">
            {tabs.map((tab, index) => {
                return (
                    <Link
                        key={index}
                        className={`${styles.link} ${active === tab.key && styles.active}`}
                        to={`?tab=${tab.key}`}>
                        <sup>0{index + 1}</sup>
                        {tab.name}
                    </Link>
                )
            })}
        </div>
    )
}

export default TabsComponent
