import { useEffect, useState } from "react";
import { useAuth } from "./auth";
import {
  Centrifuge, PublicationContext, SubscriptionStateContext,
  SubscribedContext, SubscriptionState
} from 'centrifuge';

const WS_ENDPOINT = "wss://memora.markinthedark.com/connection/websocket";

export function useBidsRealtime(cb) {
  const { isLoggedIn, access, user } = useAuth();
  const [centrifuge, setCentrifuge] = useState(null);
  const [sub, setSub] = useState(null);

  useEffect(() => {
    if (isLoggedIn && !centrifuge) {
      const options = {
        token: user.token,
        debug: true
      };

      console.log("CENTRIFUGE CONNECTION BEGIN", options);

      const c = new Centrifuge(WS_ENDPOINT, options);
      c.connect();
      const sub = c.newSubscription("bids", {

      })

      sub.on('publication', (ctx) => {
        console.log("PPUB", ctx);
        // Used to process incoming channel publications. We will talk about it soon.
        cb(ctx.data.content)
      }).subscribe();
      setCentrifuge(c);
      setSub(sub);
    } else {
      if (!isLoggedIn) centrifuge?.disconnect();
    }
  }, [isLoggedIn, centrifuge]);

}