import styles from './style.module.css'
import { useModal } from 'src/lib/providers/modal'
import ScrollFade from 'src/components/scroll-fade'

export const ContentModal = () => {
    const { closeModal, context } = useModal()

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.closeIconBox} onClick={closeModal}>
                    <div onClick={closeModal} className={styles.closeIcon}>
                        <img src="/close-icon-black.svg" alt="Close" />
                    </div>
                </div>
                <div className={styles.titleImageBox}>
                    <h3>{context?.title}</h3>
                    {context?.cover_image && (
                        <div className={styles.image}>
                            <img
                                src={context?.cover_image}
                                alt={context?.title}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.content}>
                    <ScrollFade />
                    {context?.description_full
                        .split(/\r?\n\r?\n/)
                        .map(paragraph => (
                            <p>{paragraph.trim()}</p>
                        ))}
                </div>
            </div>
        </>
    )
}

export default ContentModal
