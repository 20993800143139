import { Container } from 'react-bootstrap'
import AboutWrapper from 'src/components/about-wrapper'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function AboutUs() {
    return (
        <InnerPageWrapper darkerBackground>
            <Container>
                <AboutWrapper>
                    <h1>About us</h1>
                    <div>
                        Memora is a mission-first digital auction platform
                        transforming celebrity memorabilia into powerful
                        catalysts for change. We democratize high-end auctions,
                        connecting fans, collectors, and philanthropists with
                        exclusive A-list celebrity items guaranteed for
                        authenticity and provenance through advanced technology.
                        Each auction provides the chance to own a piece of
                        history while supporting meaningful causes, ensuring
                        100% of the hammer price goes to charity and creating a
                        significant impact in the world.
                    </div>
                    <h2>Founders' Story </h2>
                    <div>
                        Memora was born from a shared vision between two
                        friends, Eliza Kurazova and Carlota Rodben, inspired by
                        the potential of celebrity memorabilia to drive
                        substantial charitable impact. Combining Carlota’s
                        expertise in luxury foresight, innovation, and
                        storytelling with Eliza’s legal acumen, expertise in the
                        startup ecosystem and art world, they launched Memora.
                        Their goal is to merge luxury, influence, and
                        philanthropy to promote circularity through impactful
                        auctions.
                    </div>
                    <h2>Mission</h2>
                    <div>
                        We aim to turn celebrity memorabilia into impactful
                        change. By bringing back high-value collectibles into
                        the market and turning them into catalysts for positive
                        change, we ensure secure, transparent transactions and
                        authentication. We foster a community around celebrity
                        culture and philanthropy, and circularity. We make doing
                        good cool.
                    </div>
                </AboutWrapper>
            </Container>
        </InnerPageWrapper>
    )
}
