import { Col, Container, Row } from 'react-bootstrap'
import styles from './style.module.css'

const SpeedyBids = () => {
    return (
        <Container className={'mt-auto'}>
            <Row className={styles.container}>
                <Col xs={12} className={styles.title}>
                    Speedy bids
                </Col>
                <Col xs={12} lg={7}>
                    Using the same payment and
                    <br />
                    address info as your previous bid
                </Col>
                <Col xs={12} lg={5}>
                    <div className={styles.infoRow}>
                        <div>VISA ending in</div>
                        <div>1234</div>
                    </div>
                    <div className={styles.infoRow}>
                        <div>Delivery to</div>
                        <div>Los angeles, Ca</div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SpeedyBids
