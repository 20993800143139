import React, { useRef } from 'react'
import Form from 'react-bootstrap/Form'
import styles from './style.module.css'

const ResetContent = () => {
    const inputsRef = useRef([])

    const handleChange = (index, event) => {
        if (
            event.target.value.length === 1 &&
            index < inputsRef.current.length - 1
        ) {
            inputsRef.current[index + 1].focus()
        }
    }

    const handleKeyDown = (index, event) => {
        if (
            event.key === 'Backspace' &&
            index > 0 &&
            event.target.value === ''
        ) {
            inputsRef.current[index - 1].focus()
        }
    }
    return (
        <div className={styles.resetContainer}>
            {Array.from({ length: 4 }, (_, index) => (
                <Form.Control
                    key={index}
                    type="text"
                    maxLength="1"
                    className={styles.modalInput}
                    ref={el => (inputsRef.current[index] = el)}
                    onChange={e => handleChange(index, e)}
                    onKeyDown={e => handleKeyDown(index, e)}
                />
            ))}
        </div>
    )
}

export default ResetContent
