import React from 'react'
import styles from './style.module.css'
import Button from 'src/components/button'

const SecondStepVerification = () => {
    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>02</div>
                Bank verification
            </div>
            <div className={styles.bankContainer}>
                <div className={styles.bankBox}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4 className={styles.titleVerification}>
                            Bank verification
                        </h4>
                    </div>
                    <div className={styles.thirdPartyBox}>
                        <span
                            style={{
                                transform: 'rotate(-30deg)',
                                color: '#9F9B94',
                                fontSize: '47px',
                                lineHeight: '54px',
                            }}>
                            THIRD PARTY
                        </span>
                    </div>
                    <div className={styles.verificationButtonWrapper}>
                        <Button>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecondStepVerification
