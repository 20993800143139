import React from 'react'
import ImpactCard from '../user-my-impact'
import styles from './style.module.css'
const cardsArray = new Array(4).fill(null)
const ImpactList = () => {
    return (
        <div className={styles.impactCardsWrapper}>
            {cardsArray.map((el, i) => (
                <ImpactCard key={i} />
            ))}
        </div>
    )
}

export default ImpactList
