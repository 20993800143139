import { Card, Row, Col, Image } from 'react-bootstrap'
import styles from './style.module.css'
import AuctionStatus from 'src/components/auction/auction-status'
import pathConstants from 'src/routes/pathConstants'
import { Link } from 'react-router-dom'

const AuctionCard = ({ auction }) => {
    return (
        <Link to={`${pathConstants.AUCTION}/${auction.uuid}`} className="text-decoration-none">
            <Card className={`${styles.card}`}>
                <Row className="g-0" style={{ height: '100%' }}>
                    <Col
                        md={12}
                        lg={4}
                        xl={3}
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: '100%' }}>
                        <Image
                            src={auction['cover_media']['media_file']}
                            fluid
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </Col>
                    <Col
                        md={12}
                        lg={8}
                        xl={9}
                        className="d-flex flex-column p-4"
                        style={{ height: '100%' }}>
                        <Card.Title className={`${styles.title}`}>
                            {auction.title}
                        </Card.Title>
                        <div className={`${styles.cardDescription}`}>
                            <AuctionStatus auction={auction} />
                        </div>
                    </Col>
                </Row>
            </Card>
        </Link>
    )
}

export default AuctionCard
