import { createContext, useState, useContext, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

const modalContext = createContext({})

export function ModalProvider({ children }) {
    const modal = useProvideModal()

    return (
        <modalContext.Provider value={modal}>{children}</modalContext.Provider>
    )
}

export const useModal = () => useContext(modalContext)

function useProvideModal() {
    const location = useLocation()
    const [params, setSearchParams] = useSearchParams()
    const [modalType, setModalType] = useState(params.get('modal'))
    const [isOpen, setIsOpen] = useState(modalType != null)
    const [context, setContext] = useState(undefined)
    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        const authModal = params.get('auth')
        const currentModal = params.get('modal') || authModal
        if (currentModal != null) {
            if (currentModal != modalType) {
                const authModal = params.get('auth')
                setModalType(currentModal)
                setIsOpen(true)

                if (authModal) {
                    params.set('modal', authModal)
                    params.delete('auth')
                    setSearchParams(params)
                }
                // Отключаем прокрутку на основном фоне
                disableScroll()
            }
        } else {
            setModalType(null)
            setIsOpen(false)
            // Включаем прокрутку на основном фоне
            enableScroll()
        }
    }, [location])

    function openModal(type, context) {
        console.log('Opening modal:', type)
        setContext(context)
        setModalType(type)
        setIsOpen(prev => true)
        // Сохраняем текущую позицию скролла
        setScrollPosition(window.scrollY)

        // Отключаем прокрутку на основном фоне
        disableScroll()
        params.set('modal', type)
        setSearchParams(params)
    }

    function closeModal() {
        console.log('closing modal:')
        setIsOpen(false)
        setModalType(null)
        // Восстанавливаем позицию скролла
        enableScroll()
        window.scrollTo(0, scrollPosition)

        if (params.has('modal')) params.delete('modal')
        if (params.has('uid')) params.delete('uid')
        if (params.has('token')) params.delete('token')
        setSearchParams(params)
        setContext(undefined)
    }

    function disableScroll() {
        document.body.style.position = 'fixed'
        document.body.style.top = `-${scrollPosition}px`
        document.body.style.width = '100%'
    }

    function enableScroll() {
        document.body.style.position = ''
        document.body.style.top = ''
    }

    return { modalType, isOpen, openModal, closeModal, context }
}
