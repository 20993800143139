import React from 'react'
import styles from './styles.module.css'
import { useModal } from 'src/lib/providers/modal'

const AuctionDescriptionModal = () => {
    const { closeModal, context: auction } = useModal()

    // const auction = context?.auction

    // console.log(">>>>>>>>>>>>.", context)

    return (
        <div className={styles.skrollBox}>
            <div className={styles.closeIconBox} onClick={closeModal}>
                <div onClick={closeModal} className={styles.closeIcon}>
                    <img src="/close-icon-black.svg" alt="Close" />
                </div>
            </div>
            <div className={styles.americasWrapper}>
                <div className={styles.americasTitleImageBox}>
                    <h3>{auction?.title}</h3>
                    {/* <div className={styles.americasImageSection}>
                        <img src="/amer.jpg" alt="Americas for the Arts" />
                    </div> */}
                </div>
                <div className={styles.americasTextSection}>
                    <p>{auction?.description_full}</p>
                    {/* <p>
                        Björn Borg is a Swedish tennis legend renowned for his
                        ice-cool demeanor and relentless baseline play. Rising
                        to prominence in the 1970s, Borg captured the tennis
                        world’s attention with his unmatched skill and mental
                        fortitude. He is best known for winning five consecutive
                        Wimbledon titles from 1976 to 1980, a feat that cemented
                        his status as one of the greatest players in the history
                        of the sport. Borg's unique two-handed backhand and
                        tireless work ethic revolutionized the game, making him
                        a beloved figure in tennis lore.{' '}
                    </p>
                    <br />
                    <p>
                        Today, Björn Borg enjoys a fulfilling life beyond the
                        tennis courts. He is a successful entrepreneur, having
                        founded the Björn Borg fashion label, which specializes
                        in sportswear and underwear. His brand has gained
                        international recognition, combining his iconic name
                        with stylish, high-quality products.
                    </p>
                    <br />
                    <p>
                        {' '}
                        Outside of business, Borg is a devoted family man. He
                        enjoys spending time with his wife and children, living
                        a relatively private life away from the public eye.
                        Despite stepping back from professional tennis, he
                        remains connected to the sport, often participating in
                        tennis exhibitions and mentoring young players. Björn
                        Borg's legacy continues to inspire new generations of
                        players and fans around the globe, proving that his
                        influence extends far beyond his years on the court.
                    </p> */}
                    <div className={styles.americasTextGradient}></div>
                </div>
            </div>
            <div className={styles.americasTextGradient}></div>
        </div>
    )
}

export const FoundationModal = () => {
    const { closeModal, context: auction } = useModal()

    // const auction = context?.auction

    // console.log(">>>>>>>>>>>>.", context)

    return (
        <div className={styles.skrollBox}>
            <div className={styles.closeIconBox} onClick={closeModal}>
                <div onClick={closeModal} className={styles.closeIcon}>
                    <img src="/close-icon-black.svg" alt="Close" />
                </div>
            </div>
            <div className={styles.americasWrapper}>
                <div className={styles.americasTitleImageBox}>
                    <h3>{auction?.foundation?.title}</h3>
                    { auction?.foundation?.cover_image && <div className={styles.americasImageSection}>
                        <img src={auction?.foundation?.cover_image} alt={auction?.foundation?.title} />
                    </div> }
                </div>
                <div className={styles.americasTextSection}>
                    <p>{auction?.foundation?.description_full}</p>
                    {/* <p>
                        Björn Borg is a Swedish tennis legend renowned for his
                        ice-cool demeanor and relentless baseline play. Rising
                        to prominence in the 1970s, Borg captured the tennis
                        world’s attention with his unmatched skill and mental
                        fortitude. He is best known for winning five consecutive
                        Wimbledon titles from 1976 to 1980, a feat that cemented
                        his status as one of the greatest players in the history
                        of the sport. Borg's unique two-handed backhand and
                        tireless work ethic revolutionized the game, making him
                        a beloved figure in tennis lore.{' '}
                    </p>
                    <br />
                    <p>
                        Today, Björn Borg enjoys a fulfilling life beyond the
                        tennis courts. He is a successful entrepreneur, having
                        founded the Björn Borg fashion label, which specializes
                        in sportswear and underwear. His brand has gained
                        international recognition, combining his iconic name
                        with stylish, high-quality products.
                    </p>
                    <br />
                    <p>
                        {' '}
                        Outside of business, Borg is a devoted family man. He
                        enjoys spending time with his wife and children, living
                        a relatively private life away from the public eye.
                        Despite stepping back from professional tennis, he
                        remains connected to the sport, often participating in
                        tennis exhibitions and mentoring young players. Björn
                        Borg's legacy continues to inspire new generations of
                        players and fans around the globe, proving that his
                        influence extends far beyond his years on the court.
                    </p> */}
                    <div className={styles.americasTextGradient}></div>
                </div>
            </div>
            <div className={styles.americasTextGradient}></div>
        </div>
    )
}

export const DescriptionModal = () => {
    const { closeModal, context } = useModal()

    return (
        <div className={styles.skrollBox}>
            <div className={styles.closeIconBox} onClick={closeModal}>
                <div onClick={closeModal} className={styles.closeIcon}>
                    <img src="/close-icon-black.svg" alt="Close" />
                </div>
            </div>
            <div className={styles.americasWrapper}>
                <div className={styles.americasTitleImageBox}>
                    <h3>{context?.title}</h3>
                    { context?.cover_image && <div className={styles.americasImageSection}>
                        <img src={context?.cover_image} alt={context?.title} />
                    </div> }
                </div>
                <div className={styles.americasTextSection}>
                    <p>{context?.description_full}</p>
                    {/* <p>
                        Björn Borg is a Swedish tennis legend renowned for his
                        ice-cool demeanor and relentless baseline play. Rising
                        to prominence in the 1970s, Borg captured the tennis
                        world’s attention with his unmatched skill and mental
                        fortitude. He is best known for winning five consecutive
                        Wimbledon titles from 1976 to 1980, a feat that cemented
                        his status as one of the greatest players in the history
                        of the sport. Borg's unique two-handed backhand and
                        tireless work ethic revolutionized the game, making him
                        a beloved figure in tennis lore.{' '}
                    </p>
                    <br />
                    <p>
                        Today, Björn Borg enjoys a fulfilling life beyond the
                        tennis courts. He is a successful entrepreneur, having
                        founded the Björn Borg fashion label, which specializes
                        in sportswear and underwear. His brand has gained
                        international recognition, combining his iconic name
                        with stylish, high-quality products.
                    </p>
                    <br />
                    <p>
                        {' '}
                        Outside of business, Borg is a devoted family man. He
                        enjoys spending time with his wife and children, living
                        a relatively private life away from the public eye.
                        Despite stepping back from professional tennis, he
                        remains connected to the sport, often participating in
                        tennis exhibitions and mentoring young players. Björn
                        Borg's legacy continues to inspire new generations of
                        players and fans around the globe, proving that his
                        influence extends far beyond his years on the court.
                    </p> */}
                    <div className={styles.americasTextGradient}></div>
                </div>
            </div>
            <div className={styles.americasTextGradient}></div>
        </div>
    );
}

export default AuctionDescriptionModal
