const PathConstants = {
    HOME: '/',
    AUCTION: '/auction',
    AUTH: '/auth',
    PROFILE: '/profile',
    EDIT_PROFILE_INFO: '/edit_profile_info',
    EDIT_PROFILE_PASSWORD: '/edit_profile_password',
    EDIT_PROFILE_DELIVERY: '/edit_profile_delivery',
    EDIT_PROFILE_PAYMENT: '/edit_profile_payment',
    EDIT_PROFILE_EMAIL_NOTIFICATION: '/edit_profile_email_notification',

    ABOUT_US: '/about-us',
    FAQ: '/faq',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    VERIFICATION_PROFILE_INFO: '/verification_profile_info',
    VERIFICATION_BANK: '/verification_bank',
    VERIFICATION_IDENTITY: '/verification_identity',
    VERIFICATION_TERMS: '/verification_terms',
    VERIFICATION_REVIEW: '/verification_review',
}

export default PathConstants
