import React from 'react'
import BidCard from '../user-bid-card'
const bidArray = new Array(3).fill(null)

const BidList = () => {
    return (
        <>
            {bidArray.map((el, i) => (
                <BidCard key={i} isHighestBidder={Math.random() >= 0.5} />
            ))}
        </>
    )
}

export default BidList
