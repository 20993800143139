import React from 'react'
import styles from './style.module.css'
import ReactDOM from 'react-dom'
import { useModal } from 'src/lib/providers/modal'
import { MODAL_WIDE } from '../layouts/Layout'
// eslint-disable-next-line react/prop-types
const Modal = ({ children }) => {
    const { isOpen, closeModal, modalType } = useModal()
    // console.log('Modal open state:', isOpen)
    // if (!isOpen) return null
    // console.log('Modal open state22222222222222:', isOpen)

    const handleOverlayClick = e => {
        if (e.target === e.currentTarget) {
            closeModal()
        }
    }

    const isModalOpen = MODAL_WIDE.indexOf(modalType) === -1 && isOpen;

    return ReactDOM.createPortal(
        <div
            className={`${styles.modalOverlay} ${isModalOpen ? styles.show : ''}`}
            onClick={handleOverlayClick}>
            <div className={styles.modalContent}>{isModalOpen && children}</div>
        </div>,
        document.getElementById('modal-root'),
    )
}

export default Modal
