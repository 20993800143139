import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api } from "../api/client"
import { useGoogleLogin } from "../hooks/auth";
import { useAuth } from "../providers/auth";

export const GoogleOAuth2 = () => {
  const [params] = useSearchParams();
  const { loginGoogle, isLoading, error } = useGoogleLogin();
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const state = params.get('state');

  useEffect(() => {
    async function login() {
      try {
        console.log("run login google")
        loginGoogle({
          state: params.get('state'),
          code: params.get('code'),
          scope: params.get('scope'),
          authuser: params.get('authuser'),
          prompt: params.get('prompt')
        });

      } catch (error) {
        console.log("ERR");

        // setError(error);
      }
    }

    login();
  }, [state]);

  useEffect(() => {
    if (isLoggedIn) navigate('/');
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      logout();
      navigate("/?modal=login");
    }
  }, [error]);
};