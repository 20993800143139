import React from 'react'
import styles from './style.module.css'
import CheckboxContainer from './checkbox-container'
import Button from 'src/components/button'

const FourthStepVerification = () => {
    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>04</div>
                Accept terms & policies
            </div>
            <div className={styles.acceptContainer}>
                <div className={styles.acceptBox}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <div>
                            <h4 className={styles.titleVerification}>
                                Accept terms & policies
                            </h4>
                        </div>

                        <div className={styles.acceptCheckboxWrapper}>
                            <CheckboxContainer />
                        </div>
                    </div>
                    <div className={styles.verificationButtonWrapper}>
                        <Button>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FourthStepVerification
