import React from 'react'

const Icon1 = () => {
    return (
        <svg
            width="56"
            height="57"
            viewBox="0 0 56 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="0.792969" width="56" height="56" rx="28" fill="#1E2737" />
            <path
                d="M27.7111 18.893H29.9251V37.793H27.6571V24.239C27.3871 24.482 26.9551 24.59 26.3611 24.59H23.3101V22.565H26.3881C27.2791 22.565 27.7111 21.89 27.7111 20.945V18.893Z"
                fill="#FF7F47"
            />
        </svg>
    )
}

export default Icon1
