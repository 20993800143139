import React, { useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'

import Button from 'src/components/button'
const countries = [
    'Russia',
    'China',
    'Canada',
    'Germany',
    'France',
    'Japan',
    'United Kingdom',
    'United States',
    'Russia',
    'China',
    'Canada',
    'Germany',
    'France',
    'Japan',
    'United Kingdom',
    'United States',
]
const DeliveryPage = () => {
    const [hasError, setHasError] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('')
    const handleCountrySelect = country => {
        setSelectedCountry(country)
    }
    return (
        <>
            <div className={styles.passwordUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Delivery address</h4>
                    </div>
                    <div className={styles.formContainer}>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            ADRESS
                        </p>
                        <Container className={styles.informationUserContainer}>
                            <Form style={{ minHeight: 'auto' }}>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="12 Rue de la Paix"
                                            className={styles.userPageInput}
                                            name="pay"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Paris"
                                            className={styles.userPageInput}
                                            name="location"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="number"
                                            placeholder="75007"
                                            className={styles.userPageInput}
                                            name="code"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Dropdown
                                            onSelect={handleCountrySelect}>
                                            <Dropdown.Toggle
                                                className={`${styles.dropdownToggle} ${hasError ? styles.dropdownMenuError : ''} ${styles.userPageInput} w-100 d-flex justify-content-between align-items-center`}
                                                id="tax-residency-country-dropdown">
                                                <span
                                                    className={`text-start ${!selectedCountry ? styles.inactiveText : ''}`}>
                                                    {selectedCountry ||
                                                        'Tax residency country'}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                className={`w-100 `}
                                                style={{
                                                    maxHeight: '192px',
                                                    overflowY: 'auto',
                                                }}>
                                                {countries.map(
                                                    (country, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            eventKey={country}>
                                                            {country}
                                                        </Dropdown.Item>
                                                    ),
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <div className={styles.buttonBox}>
                                    <Button>Update address</Button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryPage
