import { createContext, useContext, useState } from "react";
import useLocalStorage from "../hooks/localStorage";

const tokenAuthContext = {
  // loginBasic: (data) => Promise.resolve(),
  // register: (data) => Promise.resolve(false),
  // activate: (data) => Promise.resolve(false),
  // resetPassword: (data) => Promise.resolve(false),
  // forgot: (data) => Promise.resolve(false),
  // logout: () => {},
  // // refresh,
  // // checkAuth: () => {},
  // error: undefined,
  // isLoggedIn: false,
  // isLoading: false
}

const authContext = createContext(tokenAuthContext);

export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [access, setAccess] = useLocalStorage("access", null);
  const [refresh, setRefresh] = useLocalStorage("refresh", null);
  const [user, setUser] = useLocalStorage("user", null);
  // const [error, setError] = useState<AxiosError | undefined>(undefined);

  // async function loginBasic(data: LoginInput) {
  //   setIsLoading(true);
  //   setError(undefined);

  //   try {
  //     const response: AuthResponse = await api.post('/auth/jwt/create', data);

  //     tokenStore.set("access", response.access);
  //     tokenStore.set("refresh", response.refresh);
  //     setIsLoggedIn(true);
  //     setError(undefined);
  //     setIsLoading(false);
  //   } catch(e) {
  //     if (e instanceof AxiosError) setError(e);
  //     else {
  //       console.log("UNKNOWN", e);
  //     }
  //     setIsLoading(false);
  //   }
  // }

  // async function register(data: LoginInput) {
  //   setIsLoading(true);
  //   setError(undefined);

  //   try {
  //     await api.post('/users/', data);
  //     setIsLoading(false);
  //     return true;
  //   } catch(e) {
  //     if (e instanceof AxiosError) setError(e);
  //     else {
  //       console.log("UNKNOWN", e);
  //     }
  //     setIsLoading(false);
  //     return false;
  //   }
  // }

  // async function resetPassword(data: ResetPasswordInput) {
  //   setIsLoading(true);
  //   setError(undefined);

  //   try {
  //     await api.post('/users/reset_password_confirm/', data);
  //     setIsLoading(false);
  //     return true;
  //   } catch(e) {
  //     if (e instanceof AxiosError) setError(e);
  //     else {
  //       console.log("UNKNOWN", e);
  //     }
  //     setIsLoading(false);
  //     return false;
  //   }
  // }

  // async function activate(data: ActivateInput) {
  //   setIsLoading(true);
  //   setError(undefined);

  //   try {
  //     const response = await api.post('/users/activation/', data);

  //     // tokenStore.set("access", response.access);
  //     // tokenStore.set("refresh", response.refresh);
  //     // setIsLoggedIn(true);
  //     // setError(undefined);
  //     // loginBasic(data);
  //     setIsLoading(false);
  //     return true;
  //   } catch(e) {
  //     if (e instanceof AxiosError) setError(e);
  //     else {
  //       console.log("UNKNOWN", e);
  //     }
  //     setIsLoading(false);
  //     return false;
  //   }
  // }

  // async function forgot(email: string) {
  //   setIsLoading(true);
  //   setError(undefined);

  //   try {
  //     const response = await api.post('/users/reset_password/', { email });

  //     // tokenStore.set("access", response.access);
  //     // tokenStore.set("refresh", response.refresh);
  //     // setIsLoggedIn(true);
  //     // setError(undefined);
  //     // loginBasic(data);
  //     setIsLoading(false);
  //     return true;
  //   } catch(e) {
  //     if (e instanceof AxiosError) setError(e);
  //     else {
  //       console.log("UNKNOWN", e);
  //     }
  //     setIsLoading(false);
  //     return false;
  //   }
  // }

  function logout() {
    setRefresh(null);
    setAccess(null);
    setUser(null);
    setIsLoggedIn(false);
  }

  return {
    user,
    setUser,
    isLoggedIn,
    setIsLoggedIn,
    access,
    setAccess,
    refresh,
    setRefresh,
    logout

    // loginBasic,
    // logout,
    // register,
    // activate,
    // forgot,
    // resetPassword,
    // isLoading,
    // isLoggedIn,
    // error
  }
}