const PlayIcon = () => (
    <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="28.0002" cy="27.7534" r="27.6164" fill="#D4CFC7" />
        <path
            d="M21.8633 35.4246V18.5479L35.6715 26.9862L21.8633 35.4246Z"
            fill="white"
        />
    </svg>
)

export default PlayIcon
