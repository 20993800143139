import React from 'react'
import styles from './style.module.css'
import ProgressBar from './progress-bar'
import Button from 'src/components/button'

const ThirdStepVerification = () => {
    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>03</div>
                Identity verification
            </div>
            <div className={styles.identityContainer}>
                <div className={styles.identityBox}>
                    <div className={styles.identityBoxHeader}>
                        <h4 className={styles.titleVerification}>
                            Identity verification
                        </h4>
                        <p>
                            Please upload a government-Issued ID or passport for
                            verification
                        </p>
                    </div>
                    <div className={styles.downloadBox}>
                        <div className={styles.dndBox}>
                            <input
                                type="file"
                                id="file-input"
                                className={styles.fileInput}
                                multiple
                            />
                            <label
                                htmlFor="file-input"
                                className={styles.dndLabel}>
                                Drag & Drop file here or
                                <span className={styles.chooseFile}>
                                    Choose file
                                </span>
                            </label>
                        </div>
                        <div className={styles.formatInfo}>
                            <div>Supported formats: PDF, PNG, JPEG</div>
                            <div>Maximum size: 25MB</div>
                        </div>

                        <ProgressBar
                            filename="AO_Passport.pdf"
                            percentage={40}
                        />
                        <ProgressBar
                            filename="AO_Passport.pdf"
                            percentage={40}
                        />
                        <ProgressBar
                            filename="AO_Passport.pdf"
                            percentage={100}
                        />
                        <ProgressBar
                            filename="AO_Passport.pdf"
                            percentage={40}
                        />
                    </div>
                    <div className={styles.verificationButtonWrapperThird}>
                        <Button>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThirdStepVerification
