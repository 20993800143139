import styles from './style.module.css'
import { Logo } from 'src/assets/images/Logo'
import { Col, Container, Row } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import PathConstants from '../../routes/pathConstants'
import { Link, useLocation } from 'react-router-dom'

export default function Footer({ hasExtraBottomPadding }) {
    const { pathname } = useLocation()
    if (pathname.includes('verification') || pathname.includes('profile')) {
        return null
    }
    return (
        <footer
            className={`${styles.footer} ${hasExtraBottomPadding ? styles.extraBottomPadding : ''}`}>
            <div className={`${styles.footerLogo}`}>
                <Logo />
            </div>

            <Container>
                <Row>
                    <Col xs={8}>
                        <Nav className="justify-content-start flex-column flex-lg-row">
                            <Link
                                className={`nav-link ${styles.link}`}
                                to={PathConstants.ABOUT_US}>
                                About us
                            </Link>

                            <Link
                                className={`nav-link ${styles.link}`}
                                to={PathConstants.FAQ}>
                                FAQ
                            </Link>
                            <Link
                                className={`nav-link ${styles.link}`}
                                to={PathConstants.TERMS}>
                                Terms and Conditions
                            </Link>
                            <Link
                                className={`nav-link ${styles.link}`}
                                to={PathConstants.PRIVACY}>
                                Privacy Policy
                            </Link>
                            <Nav.Link
                                className={`nav-link ${styles.link}`}
                                href="mailto:info@memora.com">
                                info@memora.com
                            </Nav.Link>
                        </Nav>
                    </Col>
                    <Col xs={4}>
                        <Nav className="justify-content-lg-end flex-column flex-lg-row">
                            <Nav.Link
                                className={`nav-link ${styles.link}`}
                                href="#">
                                Twitter
                            </Nav.Link>
                            <Nav.Link
                                className={`nav-link ${styles.link}`}
                                href="#">
                                Instagram
                            </Nav.Link>
                            <Nav.Link
                                className={`nav-link ${styles.link}`}
                                href="#">
                                TikTok
                            </Nav.Link>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
