import React from 'react'
import styles from './style.module.css'

import SidebarMenu from 'react-bootstrap-sidebar-menu'
import MemoraLogo from 'src/assets/images/memoraLogo'
import CheckedIcon from 'src/assets/images/checked'

const menuItems = [
    {
        icon: '01',
        title: 'Profile information',
        state: 'completed',
    },
    {
        icon: '02',
        title: 'Bank verification',
        state: 'active',
    },
    {
        icon: '03',
        title: 'Identity verification',
        state: 'inactive',
    },
    {
        icon: '04',
        title: 'Accept terms & policies',
        state: 'inactive',
    },
]
const VerificationLayout = ({ children }) => {
    return (
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            {' '}
            <SidebarMenu className={styles['sidebar-menu-verification']}>
                <SidebarMenu.Header className={styles['header']}>
                    <SidebarMenu.Brand className={styles['logo']}>
                        <MemoraLogo />
                    </SidebarMenu.Brand>
                </SidebarMenu.Header>
                <SidebarMenu.Body className={styles.sidebarbody}>
                    <SidebarMenu.Nav>
                        <SidebarMenu.Sub as="h4" className={styles['title']}>
                            Create your profile
                        </SidebarMenu.Sub>
                        <SidebarMenu.Sub as="p" className={styles['subtitle']}>
                            To place bids, you'll need a profile. Enter the
                            required information, and your profile will
                            typically be verified within 1-2 days.
                        </SidebarMenu.Sub>
                    </SidebarMenu.Nav>
                    <SidebarMenu.Sub>
                        <SidebarMenu.Nav>
                            {menuItems.map((item, index) => (
                                <SidebarMenu.Nav.Item
                                    className={styles.stage}
                                    key={index}>
                                    <SidebarMenu.Sub
                                        as="div"
                                        className={`${styles['icon-stage']} ${
                                            item.state === 'active'
                                                ? styles['active']
                                                : item.state === 'completed'
                                                  ? styles['completed']
                                                  : ''
                                        }`}>
                                        {item.state === 'completed' ? (
                                            <CheckedIcon />
                                        ) : (
                                            item.icon
                                        )}
                                    </SidebarMenu.Sub>
                                    <SidebarMenu.Nav.Title
                                        className={styles['text-link']}>
                                        {item.title}
                                    </SidebarMenu.Nav.Title>
                                </SidebarMenu.Nav.Item>
                            ))}
                        </SidebarMenu.Nav>
                    </SidebarMenu.Sub>
                </SidebarMenu.Body>
            </SidebarMenu>
            {children}
        </div>
    )
}

export default VerificationLayout
