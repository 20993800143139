import styles from './style.module.css'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useCallback, useEffect, useRef, useState } from 'react'
import 'swiper/css'
import ArrowPrev from 'src/assets/images/arrow-prev'
import ArrowNext from 'src/assets/images/arrow-next'
import { MOBILE_BREAKPOINT } from 'src/config'

const LotGallery = ({ images }) => {
    const swiperRef = useRef(null)

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < MOBILE_BREAKPOINT) // Adjust the breakpoint as needed
        }

        handleResize() // Check on initial load
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return
        swiperRef.current.swiper.slidePrev()
    }, [])

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return
        swiperRef.current.swiper.slideNext()
    }, [])

    return (
        <Col md={12} lg={6} className={styles.galleryContainer}>
            {isMobile ? (
                <>
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={0}
                        loop
                        slidesPerView={1.1}>
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div
                                    className={styles.mobileImage}
                                    style={{
                                        backgroundPosition: 'center',
                                        backgroundsize: 'cover',
                                        backgroundImage: `url('${image}')`,
                                    }}></div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className={styles.swiperNavigation}>
                        <div
                            className={styles.navigationArrow}
                            onClick={handlePrev}>
                            <ArrowPrev />
                        </div>
                        <div
                            className={styles.navigationArrow}
                            onClick={handleNext}>
                            <ArrowNext />
                        </div>
                    </div>
                </>
            ) : (
                images.map((image, index) => (
                    <Col key={index} md={12} className={styles.desktopImage}>
                        <Image
                            src={image}
                            alt={`gallery-${index}`}
                            fluid
                            className="w-100"
                        />
                    </Col>
                ))
            )}
        </Col>
    )
}
export default LotGallery
