import Home from '../pages/Home'
import Auction from '../pages/Auction'
import PathConstants from './pathConstants'
import Lot from '../pages/Lot'
import Profile from 'src/pages/Profile'
import AboutUs from '../pages/AboutUs'
import Faq from '../pages/Faq'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import VerificationLayout from 'src/components/verification-user'
import FirstStepVerification from 'src/components/verification-user/first-step-verification'
import SecondStepVerification from 'src/components/verification-user/second-step-verification'
import ThirdStepVerification from 'src/components/verification-user/third-step-verification'
import FourthStepVerification from 'src/components/verification-user/fourth-step-verification'
import ReviewingVerification from 'src/components/verification-user/reviewing-verification'
import ProfileInformationPage from 'src/components/user-profile-information/profile-information-page'
import UserProfileLayout from 'src/components/user-profile-information'
import PasswordPage from 'src/components/user-profile-information/password'
import DeliveryPage from 'src/components/user-profile-information/delivery'
import PaymentPage from 'src/components/user-profile-information/payment'
import EmailNotificationPage from 'src/components/user-profile-information/email-notification'

const routes = [
    { path: PathConstants.HOME, element: <Home /> },
    {
        path: PathConstants.EDIT_PROFILE_INFO,
        element: (
            <UserProfileLayout>
                <ProfileInformationPage />
            </UserProfileLayout>
        ),
    },
    {
        path: PathConstants.EDIT_PROFILE_PASSWORD,
        element: (
            <UserProfileLayout>
                <PasswordPage />
            </UserProfileLayout>
        ),
    },
    {
        path: PathConstants.EDIT_PROFILE_DELIVERY,
        element: (
            <UserProfileLayout>
                <DeliveryPage />
            </UserProfileLayout>
        ),
    },
    {
        path: PathConstants.EDIT_PROFILE_PAYMENT,
        element: (
            <UserProfileLayout>
                <PaymentPage />
            </UserProfileLayout>
        ),
    },
    {
        path: PathConstants.EDIT_PROFILE_EMAIL_NOTIFICATION,
        element: (
            <UserProfileLayout>
                <EmailNotificationPage />
            </UserProfileLayout>
        ),
    },
    { path: PathConstants.PROFILE, element: <Profile /> },
    { path: `${PathConstants.AUCTION}/:uuid`, element: <Auction /> },
    {
        path: `${PathConstants.AUCTION}/:uuid/:lot_uuid`,
        element: <Lot />,
        name: 'Lot',
    },
    { path: PathConstants.ABOUT_US, element: <AboutUs /> },
    { path: PathConstants.FAQ, element: <Faq /> },
    { path: PathConstants.TERMS, element: <Terms /> },
    { path: PathConstants.PRIVACY, element: <Privacy /> },
    {
        path: PathConstants.VERIFICATION_PROFILE_INFO,
        element: (
            <VerificationLayout>
                <FirstStepVerification />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_BANK,
        element: (
            <VerificationLayout>
                <SecondStepVerification />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_IDENTITY,
        element: (
            <VerificationLayout>
                <ThirdStepVerification />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_TERMS,
        element: (
            <VerificationLayout>
                <FourthStepVerification />
            </VerificationLayout>
        ),
    },
    {
        path: PathConstants.VERIFICATION_REVIEW,
        element: (
            <VerificationLayout>
                <ReviewingVerification />
            </VerificationLayout>
        ),
    },
]
export default routes
