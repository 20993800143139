const ArrowNext = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill="black" />
            <g clipPath="url(#clip0_215_11840)">
                <path
                    d="M9.51562 18.0005H26.4862M26.4862 18.0005L18.3757 9.89003M26.4862 18.0005L18.3757 26.1109"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M9.51562 18.0005H26.4862M26.4862 18.0005L18.3757 9.89003M26.4862 18.0005L18.3757 26.1109"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M9.51562 18.0005H26.4862M26.4862 18.0005L18.3757 9.89003M26.4862 18.0005L18.3757 26.1109"
                    stroke="white"
                    strokeWidth="1.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_215_11840">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(9 9)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowNext
