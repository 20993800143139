import styles from './style.module.css'

const Winner = () => {
    const text = '$8500'
    return (
        <>
            <div className={styles.container}>
                <div className={styles.animatedRow}>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                </div>
                <div className={styles.animatedRow}>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                </div>
                <div className={styles.animatedRow}>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                </div>
                <div className={styles.animatedRow}>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                    <span className={styles.animatedText}>
                        {text.repeat(10)}{' '}
                    </span>
                </div>
            </div>
            <div className={styles.winnerText}>You’ve won!</div>
        </>
    )
}

export default Winner
