import React from 'react'
import styles from './style.module.css'

import { useModal } from 'src/lib/providers/modal'

const TermsAndConditions = ({ title = 'Terms & Conditions' }) => {
    const { closeModal } = useModal()
    return (
        <>
            <div className={styles.closeIconBox}>
                <button
                    onClick={closeModal}
                    className={styles.closeButtonTerms}>
                    <img src="/close-icon.svg" alt="Close" />
                </button>
            </div>
            <div className={styles.termsWrapper}>
                <div className={styles.termsTitleImageBox}>
                    <div style={{ minWidth: '50%' }}>
                        <h3>{title}</h3>
                    </div>
                    <div className={styles.termsImageSection}></div>
                </div>
                <div className={styles.termsTextSection}>
                    <div style={{ height: '90px' }}></div>
                    <p>
                        These Terms and Conditions are a binding legal contract
                        between Etika Inc ("MEMORA") and you. These Terms apply
                        to and govern your use of the MEMORA website located at
                        www.memora-house.com (“Site” or “Platform”) and any of
                        our related services or content (the Site and these
                        services and content are together referred to herein as
                        the “Services”).
                    </p>
                    <br />
                    <p>
                        {' '}
                        Please read these terms carefully and do not use this
                        Site or the Services if you do not agree with these
                        Terms of Use. The Services include auctions, for a
                        variety of Asset (hereinafter defined). By using this
                        Site and participating in these auctions, you agree to
                        be bound to any purchases you win, and you further agree
                        to be bound by these terms and conditions. If you do not
                        agree, do not use the Platform.{' '}
                    </p>
                    <br />
                    <p>
                        The most recent version of these Terms will always be
                        available on the Platform. MEMORA reserves the right to
                        modify these Terms at any time. Continued use of the
                        Platform after changes indicates your acceptance of the
                        updated Terms.{' '}
                    </p>
                    <br />
                    <p>
                        {' '}
                        The Services are available only to individuals who can
                        form legally binding contracts under applicable law and
                        for non-commercial purposes in compliance with these
                        Terms of Use. Without limiting the foregoing, our
                        Services are not available to minors under the age of
                        18, nor persons who are barred from receiving the
                        Services under the laws of the United States or other
                        applicable jurisdictions. You may not use the Services
                        if you do not qualify, and your use of the Services is
                        your representation that you do qualify. Notice
                        regarding dispute resolution: these terms of use contain
                        provisions that govern how claims you and MEMORA have
                        against each other are resolved, including an obligation
                        to arbitrate disputes, which will, subject to limited
                        exceptions, require you to submit claims you have
                        against MEMORA to binding arbitration, unless you
                        opt-out in accordance with the terms hereof. Unless you
                        opt-out of arbitration: (a) you will only be permitted
                        to pursue claims against MEMORA on an individual basis,
                        not as part of any class or representative action or
                        proceeding and (b) you will only be permitte
                    </p>
                    <br />

                    <div className={styles.termsTextGradient}></div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions
