import React, { useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

const notifications = [
    {
        title: 'Newsletter Updates',
        subTitle:
            'Keep up to date with the latest from our team, upcoming auctions, and much more.',
        state: true,
    },
    {
        title: 'Bid Activity Alerts',
        subTitle: 'Get notified when the lot you are watching just got bid on.',
        state: true,
    },
    {
        title: 'Lot Sold Notifications',
        subTitle:
            'Receive alerts when the lot you are watching just sold for a certain amount.',
        state: false,
    },
    {
        title: 'Auction Reminders',
        subTitle:
            'Receive reminders for upcoming auctions you might be interested in.',
        state: false,
    },
    {
        title: 'Outbid Alerts',
        subTitle: `Be informed immediately if you've been outbid.`,
        state: true,
    },
    {
        title: 'Winning Bid Notifications',
        subTitle: `Congratulations! Get notified when you win a lot.`,
        state: true,
    },
]

const EmailNotificationPage = () => {
    return (
        <>
            <div className={styles.notificationUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Email notifications</h4>
                    </div>
                    <div className={styles.formContainer}>
                        <Container
                            className={styles.emailNotificationContainer}>
                            <Form>
                                {notifications.map(el => (
                                    <div
                                        className={styles.checkboxContainer}
                                        key={el.title}>
                                        <Form.Check
                                            checked={el.state}
                                            className={`${styles.switchItem} ${el.state ? styles.checked : ''}`}
                                            reverse
                                            label={
                                                <div
                                                    className={
                                                        styles.labelBlock
                                                    }>
                                                    {el.title}
                                                    <div
                                                        className={
                                                            styles.linkText
                                                        }>
                                                        {el.subTitle}
                                                    </div>
                                                </div>
                                            }
                                            name="group1"
                                            type="switch"
                                        />
                                        <hr
                                            className={
                                                styles.dividerNotification
                                            }
                                        />
                                    </div>
                                ))}
                                <div className={styles.buttonBox}>
                                    <button
                                        className={`${styles.btnUserPage} ${styles.active} ${styles.resizeNotificationButton}`}>
                                        Save
                                    </button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailNotificationPage
