import { Col, Row } from 'react-bootstrap'
import styles from './style.module.css'
import Button from 'src/components/button'
import React, { useState } from 'react'
import Overview from './overview'
import EditPayment from './edit-payment'
import EditAddress from './edit-address'
import BidStatus from '../bid-status'
import { IS_MOBILE, MOBILE_BREAKPOINT } from 'src/config'

const ReviewBid = ({ setStep, lot }) => {
    const isEnableButton = true
    const [state, setState] = useState(0) // 0 overview, 1 payment, 2 adress
    const [confirming, setConfirming] = useState(true)
    const amount = 7000

    const isEnabled = state === 0

    const hideBidColumn = IS_MOBILE && (state === 1 || state === 2)

    const confirmBid = () => {
        setConfirming(true)

        setTimeout(() => {
            setStep(0)
            setConfirming(false)
        }, 2000)
    }

    return (
        <Row className={`flex-column flex-lg-row ${styles.rowWrapper}`}>
            <Col xs={12} lg={6} className="d-flex flex-column">
                {state === 0 && (
                    <Overview setStep={setStep} setState={setState} lot={lot} />
                )}
                {state === 1 && <EditPayment setState={setState} lot={lot} />}
                {state === 2 && <EditAddress setState={setState} lot={lot} />}
                {!IS_MOBILE && <BidStatus />}
            </Col>

            <Col xs={12} lg={6} className={hideBidColumn ? 'd-none' : ''}>
                <div
                    className={`${styles.container} ${isEnabled ? '' : styles.disabled}`}>
                    <div className={styles.amount}>
                        {confirming ? 'Bid placed' : amount}
                    </div>

                    {!confirming && (
                        <div
                            className={`${styles.actions} ${isEnableButton ? '' : styles.disabled}`}>
                            <Button
                                disabled={!isEnabled}
                                fullWidth={false}
                                onClick={confirmBid}>
                                Confirm bid
                            </Button>

                            <div className={styles.agreeText}>
                                By placing your bid, you acknowledge that you
                                have read and agree to our{' '}
                                <a href="#">Terms and Conditions</a> and{' '}
                                <a href="#">Bidding Rules</a>.
                            </div>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default ReviewBid
