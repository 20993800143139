import React, { useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import metamaskIcon from 'src/assets/images/metam3.jpg'
import Button from 'src/components/button'

const FirstStepVerification = () => {
    const [hasPhoto, setHasPhoto] = useState(false)
    const photoUrl = metamaskIcon
    const handlePhotoUpload = () => {
        setHasPhoto(true)
    }

    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>01</div>
                Profile information
            </div>
            <div className={styles.informationWrapper}>
                <div className={styles.verificationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Profile information</h4>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            USER INFORMATION
                        </p>
                        <div className={styles.uploadPhotoBox}>
                            <div
                                className={styles.photo}
                                style={{
                                    backgroundColor: hasPhoto
                                        ? 'transparent'
                                        : 'rgba(212, 207, 199, 1)',
                                    backgroundImage: hasPhoto
                                        ? `url(${photoUrl})`
                                        : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                <p>Upload photo</p>
                                {!hasPhoto && (
                                    <div className={styles.photoIconMobile}>
                                        <img
                                            src="/emptyIcon.svg"
                                            alt="Upload Photo"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.uploadButtonBox}>
                                <Button
                                    variant="white"
                                    onClick={handlePhotoUpload}>
                                    Upload new photo
                                </Button>
                                <Button
                                    style={{ marginTop: '16px' }}
                                    variant="neutral-dark"
                                    onClick={handlePhotoUpload}>
                                    Delete photo
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.formContainer}>
                        <Container className={styles.bootstrapContainer}>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            className={styles.userPageInput}
                                            name="email"
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Username"
                                            className={styles.userPageInput}
                                            name="username"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="First name"
                                            className={styles.userPageInput}
                                            name="firstName"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last name"
                                            className={styles.userPageInput}
                                            name="lastName"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone number"
                                            className={styles.userPageInput}
                                            name="phoneNumber"
                                        />
                                    </Col>{' '}
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Tax residency country"
                                            className={styles.userPageInput}
                                            name="taxCountry"
                                        />
                                    </Col>
                                </Row>

                                <p className={styles.deliveryText}>
                                    DELIVERY ADDRESS
                                </p>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Address"
                                            className={styles.userPageInput}
                                            name="address"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="City"
                                            className={styles.userPageInput}
                                            name="city"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Country"
                                            className={styles.userPageInput}
                                            name="country"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </div>
                    <div className={styles.buttonBox}>
                        <Button>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstStepVerification
