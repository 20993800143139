import React from 'react'
import styles from './style.module.css'
import Button from '../button'
import tennisImage from 'src/assets/images/image16.jpg'

const OwnedCard = () => {
    return (
        <div className={styles.card}>
            <div className={styles.gridContainer}>
                <div className={styles.lotAbout}>
                    <div className={styles.lotTitleBox}>
                        <div>
                            <div className={styles.subTitle}>The Item</div>
                            <div
                                style={{
                                    fontFamily: 'Aeonik',
                                    fontSize: '27px',
                                    lineHeight: '31px',
                                    marginTop: '16px',
                                }}>
                                Lot #8: Björn Borg’s Iconic Fila Pre-Game Jacket
                            </div>
                        </div>
                        <div className={styles.imageContainerMobile}>
                            {' '}
                            <img
                                src={tennisImage}
                                alt="image"
                                className={styles.image}
                            />
                        </div>
                        <div className={styles.buttonDetail}>
                            <Button variant="neutral-dark" fullWidth={false}>
                                Item details
                            </Button>
                        </div>
                    </div>
                    <div className={styles.imageContainerDesk}>
                        {' '}
                        <img
                            src={tennisImage}
                            alt="image"
                            className={styles.image}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.charityBox}>
                <div className={`${styles.textContent} `}>
                    <div className={styles.subTitle}>The Charity</div>
                    <div className={styles.title}>Americas for the arts</div>
                    <div className={`${styles.text} ${styles.truncate}`}>
                        <p>
                            {' '}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ullamcorper malesuada proin
                            libero nunc consequat interdum varius sit amet. Vel
                            pretium lectus quam id. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ullamcorper malesuada proin libero nunc consequat
                            interdum varius sit amet. Vel pretium lectus quam id{' '}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ullamcorper malesuada proin
                            libero nunc consequat interdum varius sit amet. Vel
                            pretium lectus quam id. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ullamcorper malesuada proin libero nunc consequat
                            interdum varius sit amet. Vel pretium lectus quam id
                        </p>
                        <button className={styles.learn}>Learn more</button>
                    </div>
                </div>
                <div className={styles.textContent}>
                    <div className={styles.subTitle}>THE CELEBRITY</div>
                    <div className={styles.title}>Bitta Borg</div>
                    <div
                        className={`${styles.text} ${styles.truncate} ${styles.celebrity}`}>
                        <p>
                            {' '}
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ullamcorper malesuada proin
                            libero nunc consequat interdum varius sit amet. Vel
                            pretium lectus quam id. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ullamcorper malesuada proin libero nunc consequat
                            interdum varius sit amet. Vel pretium lectus quam id{' '}
                        </p>
                        <button className={styles.learn}>Learn more</button>
                    </div>
                </div>
                <div className={styles.textContent}>
                    <div className={styles.subTitle}>Your donation</div>
                    <div className={styles.title}>$10,000.00</div>
                </div>
            </div>
            <div className={styles.paymentInfo}>
                <div className={styles.paymentFlex}>
                    <p className={styles.subTitle}>Item timeline</p>
                    <ul>
                        <li>
                            <div>
                                <p>Winning bid</p>
                                <p>$10,000.00</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Payment processed</p>
                                <p>VISA 8192</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <p>Item mailed</p>
                                <p>5015 Evans Ave, Los Angeles, CA</p>
                            </div>
                        </li>
                        <li style={{ opacity: '0.4' }}>
                            <div>
                                <p>August 8, 2024</p>
                                <p> Connected to Metamask</p>
                            </div>
                        </li>
                    </ul>
                    <Button>Connect wallet</Button>
                </div>
            </div>
        </div>
    )
}

export default OwnedCard
