import React from 'react'

const closeIcon = ({ onClick }) => {
    return (
        <svg
            width="36"
            height="37"
            viewBox="0 0 36 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}>
            <circle cx="18" cy="18.793" r="18" fill="#D4CFC7" />
            <path
                d="M12.75 13.543L22.5 23.293"
                stroke="black"
                // stroke-width="1.5"
            />
            <path
                d="M22.5 13.543L12.75 23.293"
                stroke="black"
                // stroke-width="1.5"
            />
        </svg>
    )
}

export default closeIcon
