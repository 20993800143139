import styles from './style.module.css'
import BackHeader from 'src/components/bidding/back-header'
import visa from 'src/assets/images/visa.png'
import Checkbox from 'src/components/bidding/checkbox'
import React from 'react'
const Overview = ({ setStep, setState, lot }) => {
    return (
        <>
            <BackHeader
                action={() => setStep(0)}
                title={'Review your bid'}
                lot={lot}
            />
            <div className={styles.content}>
                <div className="d-flex flex-column flex-lg-row">
                    <div className={styles.block}>
                        <div className={styles.blockHead}>
                            <div className={styles.blockTitle}>Payment</div>
                            <div
                                className={styles.blockLink}
                                onClick={() => setState(1)}>
                                Edit
                            </div>
                        </div>
                        <div className={styles.blockContent}>
                            <div className={styles.card}>
                                <img
                                    alt="visa"
                                    src={visa}
                                    className={styles.cardType}
                                />
                                <span>3456</span>
                            </div>
                            <div>Expires 12/2025</div>
                        </div>
                    </div>
                    <div className={styles.block}>
                        <div className={styles.blockHead}>
                            <div className={styles.blockTitle}>
                                Delivery address
                            </div>
                            <div
                                className={styles.blockLink}
                                onClick={() => setState(2)}>
                                Edit
                            </div>
                        </div>
                        <div className={styles.blockContent}>
                            <div className={styles.address}>
                                5015 Evans ave Austin, TX 78751
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.speedyBids}>
                        <div>
                            <div className={styles.blockTitle}>Speedy bids</div>
                            <div>
                                Save payment and delivery info for this lot to
                                bid faster if outbid
                            </div>
                        </div>
                        <div>
                            <Checkbox
                                onChange={e => {
                                    console.log('change', e.target.checked)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Overview
