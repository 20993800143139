import React from 'react'
import styles from './style.module.css'
import ContentLogin from 'src/components/modal/login/content-login'
import CloseIcon from 'src/assets/images/closeIcon'
import Title from 'src/components/modal/modals-components/title'
import CustomForm from 'src/components/modal/modals-components/form'
import SocialIcons from '../login/socialIcons'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'
import { t } from 'src/lib/i18n'
import Form from 'react-bootstrap/Form'
import formStyles from '../modals-components/form/style.module.css'
import { useGoogleLoginLink, useRegister } from 'src/lib/hooks/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'src/lib/providers/auth'
import AccountCreated from '../account-created'

import loginStyles from '../login/style.module.css';

const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
})

// eslint-disable-next-line react/prop-types
const GetStarted = ({ handleCloseModal }) => {
    const { openModal } = useModal()
    const { register, error, isLoading, isRegistered } = useRegister()
    const { googleURL } = useGoogleLoginLink();

    const failedAttemptError =
        error?.response?.status === 400 ? 'Email already taken' : error?.message

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: RegisterSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => register(values),
    })

    if (isRegistered) return <AccountCreated email={formik.values.email} />

    return (
        <div className={styles.getStartedWrapper}>
            <Form onSubmit={formik.handleSubmit}>
                <div className={styles.wrapperStarted}>
                    <Title
                        handleCloseModal={handleCloseModal}
                        isEndPosition
                        title="Get started">
                        <CloseIcon onClick={handleCloseModal} />
                    </Title>
                    <Form.Control
                        type="text"
                        placeholder={t('Email')}
                        className={formStyles.modalInput}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        autoFocus={true}
                    />
                    {formik.errors.email}
                    <Form.Control
                        type="password"
                        placeholder={t('Password')}
                        className={formStyles.modalInput}
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.password}
                </div>
                <div className={styles.modalFormBoxGetStarted}>
                    {/* <div className={styles.linkForgotBox}></div> */}
                    <div className={styles.loginBox}>
                        <button
                            type="submit"
                            className={styles.btnLogin}
                            disabled={isLoading}>
                            {isLoading ? t('Loading..') : t('Create account')}
                        </button>
                        {failedAttemptError}
                        <Link
                            className={styles.link}
                            onClick={() => openModal(MODAL.LOGIN)}>
                            {t('Login')}
                        </Link>
                    </div>
                    <div>
                        <div className={loginStyles.footerText}>
                            OR CONTINUE WITH
                        </div>
                        <SocialIcons googleURL={googleURL} />
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default GetStarted
