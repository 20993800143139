import styles from './style.module.css'
const Spinner = () => {
    return (
        <div className={`${styles.progressBar}`}>
            <div className={`${styles.circle} ${styles.border}`}></div>
        </div>
    )
}

export default Spinner
