import React, { useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

const PasswordPage = () => {
    return (
        <>
            <div className={styles.passwordUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Password</h4>
                    </div>
                    <div className={styles.formContainer}>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            UPDATE PASSWORD
                        </p>
                        <Container className={styles.informationUserContainer}>
                            <Form style={{ minHeight: 'auto' }}>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="password"
                                            placeholder="Old password"
                                            className={styles.userPageInput}
                                            name="oldPassword"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="password"
                                            placeholder="New password"
                                            className={styles.userPageInput}
                                            name="newPassword"
                                        />
                                    </Col>
                                </Row>

                                <div className={styles.buttonBox}>
                                    <button
                                        className={`${styles.btnUserPage} ${styles.active} ${styles.resizeButton}`}>
                                        Update password
                                    </button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordPage
