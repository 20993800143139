import React from 'react'
import styles from './style.module.css'
import SignContent from 'src/components/modal/sign/sign-content'
import BackIcon from 'src/assets/images/backIcon'
import CustomForm from 'src/components/modal/modals-components/form'
import Title from 'src/components/modal/modals-components/title'
import { useNavigate } from 'react-router-dom'

import { t } from 'src/lib/i18n'
import Form from 'react-bootstrap/Form'
import formStyles from '../modals-components/form/style.module.css'
import recoverStyles from './sign-content/style.module.css'
import { useLogin, usePasswordRecover } from 'src/lib/hooks/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'src/lib/providers/auth'
import ResetPassword from '../reset-password'

const RecoverSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
})

// eslint-disable-next-line react/prop-types
const Sign = () => {
    const navigate = useNavigate()
    const { recover, isLoading, isRecovered, error } = usePasswordRecover()

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: RecoverSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => recover(values),
    })

    // FIXME: SECURITY: should backend response with any error here?
    if (isRecovered || error)
        return <ResetPassword email={formik.values.email} />

    return (
        <>
            <Form onSubmit={formik.handleSubmit} className={styles.formSign}>
                <div className={styles.wrapperItemSign}>
                    <div className={styles.wrapperForgot}>
                        <Title
                            handleCloseModal={() => navigate(-1)}
                            title="Forgot password?"
                            subTitle="No problem, we’ll help you reset it.">
                            <BackIcon onClick={() => navigate(-1)} />
                        </Title>
                        <Form.Control
                            type="text"
                            placeholder={t('Email')}
                            className={formStyles.modalInput}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            autoFocus={true}
                        />
                        {formik.errors.email}
                    </div>
                    <div className={styles.modalFormBox}>
                        <div className={recoverStyles.forgotWrapper}>
                            <button
                                type="submit"
                                className={recoverStyles.btn}
                                disabled={isLoading}>
                                {isLoading ? t('Loading..') : t('Continue')}
                            </button>
                        </div>
                        <div className={recoverStyles.steps}>STEP {1} of 4</div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default Sign
