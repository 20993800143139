import styles from './style.module.css'
import { useState } from 'react'

const SortDrowdown = ({ options, initialSort }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedSort, setSelectedSort] = useState(initialSort)

    const handleToggle = () => {
        setIsOpen(!isOpen)
    }

    const handleSelect = option => {
        setSelectedSort(option)
        setIsOpen(false)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.sortDropdown}>
                <div className={styles.sortCurrent} onClick={handleToggle}>
                    Sort by: {selectedSort}
                </div>
                {isOpen && (
                    <div className={styles.sortOptions}>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={styles.sortOption}
                                onClick={() => handleSelect(option)}>
                                <label className={styles.sortOptionLabel}>
                                    {option}
                                    <input
                                        type="radio"
                                        name="radio"
                                        checked={option === selectedSort}
                                    />
                                    <span
                                        className={
                                            styles.sortOptionCheckmark
                                        }></span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SortDrowdown
