import { Outlet, useLocation, useMatch } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import Loader from './Loader'
import { Suspense } from 'react'
import Login from 'src/components/modal/login'
import { useEffect, useState } from 'react'
import Sign from 'src/components/modal/sign'
import ResetPassword from 'src/components/modal/reset-password'
import NewPassword from '../modal/new-password'
import SuccessReset from '../modal/success-reset'
import Modal from 'src/components/modal'
import GetStarted from '../modal/get-started'
import AccountCreated from '../modal/account-created'
import WouldYouPrefer from '../modal/delete-account-group-modals/whould-you-prefer'
import AreYouSure from '../modal/delete-account-group-modals/are-you-sure'
import SuccessDelete from '../modal/delete-account-group-modals/successful-delete'
import SecureYourDigitalId from '../modal/secure-your-digital'
import { useModal } from 'src/lib/providers/modal'
import { useAutologin } from 'src/lib/hooks/auth'
import AccountActivation from '../modal/account-activation'
import AuctionDescriptionModal, {
    DescriptionModal,
    FoundationModal,
} from '../modal/americas-for-the-arts'

import VerificationLayout from '../verification-user'

import SecondStepVerification from '../verification-user/second-step-verification'
import FirstStepVerification from '../verification-user/first-step-verification'
import CreateProfileMobile from '../verification-user/sidebar-mobile-first-step'
import ThirdStepVerification from '../verification-user/third-step-verification'
import FourthStepVerification from '../verification-user/fourth-step-verification'
import ModalTextContent from '../modal-text-content'
import TermsAndConditions from '../verification-user/fourth-step-verification/modals/accept-terms-modal'
import LastStepVerification from '../verification-user/reviewing-verification'
import { GoogleOAuth2 } from 'src/lib/components/google-oauth2'
import UserProfileLayout from '../user-profile-information'
import ProfileInformationPage from '../user-profile-information/profile-information-page'
import PasswordPage from '../user-profile-information/password'
import DeliveryPage from '../user-profile-information/delivery'
import PaymentPage from '../user-profile-information/payment'
import EmailNotificationPage from '../user-profile-information/email-notification'

import SidebarUserProfileMobile from '../user-profile-information/sidebar-mobile-profile-information'
import TestModal from '../modal/test-modal'
import DigitalIdModal from '../modal/digital-id'
import VideoModal from '../modal/video'
import ContentModal from 'src/components/modal/content-modal'
import { useParams } from 'react-router'
import routes from '../../routes'

export const MODAL = {
    LOGIN: 'login',
    REGISTER: 'register',
    FORGOT_PASSWORD: 'forgot_password',
    RESET_PASSWORD: 'reset_password',
    ACTIVATE: 'activate',
    GOOGLE: 'google',
    AUCTION_DESC: 'auction_desc',
    AUCTION_FOUNDATION: 'auction_foundation',
    LOT_OWNER: 'lot_owner',
    DIGITAL_ID: 'digital_id',
    VIDEO: 'video',
    ACCEPT_TERMS: 'accept_terms',
    CONTENT_MODAL: 'content_modal',
}

export const MODAL_WIDE = [
    MODAL.AUCTION_DESC,
    MODAL.AUCTION_FOUNDATION,
    MODAL.DIGITAL_ID,
    MODAL.CONTENT_MODAL,
    MODAL.VIDEO,
    MODAL.ACCEPT_TERMS,
]

function ModalContent() {
    const { modalType } = useModal()

    return (
        <>
            <Modal>
                {modalType === MODAL.LOGIN && <Login />}
                {modalType === MODAL.FORGOT_PASSWORD && <Sign />}
                {modalType === MODAL.RESET_PASSWORD && <NewPassword />}
                {modalType === MODAL.REGISTER && <GetStarted />}
                {modalType === MODAL.ACTIVATE && <AccountActivation />}
                {modalType === MODAL.GOOGLE && <GoogleOAuth2 />}
            </Modal>
            <ModalTextContent>
                {modalType === MODAL.CONTENT_MODAL && <ContentModal />}
                {modalType === MODAL.AUCTION_DESC && (
                    <AuctionDescriptionModal />
                )}
                {modalType === MODAL.AUCTION_FOUNDATION && <DescriptionModal />}
                {modalType === MODAL.DIGITAL_ID && <DigitalIdModal />}
                {modalType === MODAL.VIDEO && <VideoModal />}
                {modalType === MODAL.ACCEPT_TERMS && <TermsAndConditions />}
            </ModalTextContent>
        </>
    )
}

export default function Layout() {
    const { isLoading } = useAutologin()
    const { pathname } = useLocation()
    let hasExtraBottomPadding = false

    const findMatchingRoute = () => {
        for (const route of routes) {
            const routePattern = new RegExp(
                `^${route.path.replace(/:\w+/g, '([^/]+)')}$`,
            )
            if (routePattern.test(pathname)) {
                return route
            }
        }
        return null
    }
    const matchingRoute = findMatchingRoute()

    if (matchingRoute && matchingRoute.name === 'Lot') {
        hasExtraBottomPadding = true
    }

    if (isLoading) return <></>

    return (
        <>
            <Header />
            <main>
                <Suspense fallback={<Loader />}>
                    <Outlet />
                </Suspense>
            </main>
            <Footer hasExtraBottomPadding={hasExtraBottomPadding} />

            {/* <SidebarUserProfileMobile /> */}
            {/* <ModalTextContent>
            <AuctionDescriptionModal />
            <TermsAndConditions />
            </ModalTextContent> */}
            <ModalContent />
            {/* <TestModal /> */}
            {/* <Modal>
                <ModalContent />
                {/* <SuccessDelete handleCloseModal={closeModal} /> */}
            {/* <SecureYourDigitalId handleCloseModal={handleCloseModal} /> */}
            {/* <AreYouSure handleCloseModal={handleCloseModal} /> */}
            {/* <WouldYouPrefer handleCloseModal={handleCloseModal} /> */}
            {/* <Sign handleCloseModal={handleCloseModal} /> */}
            {/* <SuccessReset handleCloseModal={handleCloseModal} /> */}
            {/* <NewPassword handleCloseModal={handleCloseModal} /> */}
            {/* <Login handleCloseModal={handleCloseModal} /> */}
            {/* <ResetPassword handleCloseModal={handleCloseModal} /> */}
            {/* <GetStarted handleCloseModal={handleCloseModal} /> */}
            {/* <AccountCreated handleCloseModal={handleCloseModal} /> */}
            {/* </Modal> */}
        </>
    )
}
