import styles from './style.module.css'
import Label from 'src/components/label'
import moment from 'moment';
import { useEffect, useState } from 'react';

const statusConfig = {
    live: { variant: 'live', text: 'Watch livestream' },
    prebid: { variant: 'prebid', text: 'Accepting pre-bids' },
    closed: { variant: 'closed', text: 'Closed' },
    soon: { variant: 'closed', text: 'Coming soon' },
}

function getStatus(auction) {
    return 'live';
}

// to: Moment
export function countdown(to) {
    const now = moment();
    const duration = moment.duration(to.diff(now));

    return `${duration.days()}:${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
}

export function formatDate(datestr) {
    return moment(datestr).format("MMMM DD, ha")
}

export const useTimeRemaining = ({ to }) => {
    const dateEnd = moment(to);
    const [timeTo, setTimeTo] = useState(countdown(dateEnd));

    useEffect(() => {
        const id = setTimeout(() => {
        setTimeTo(countdown(dateEnd));
        }, 1000);

        return () => {
        clearTimeout(id);
        };
    });

    return { timeTo }
}

export const TimeRemaining = ({ to }) => {
    const dateEnd = moment(to);
    const [timeTo, setTimeTo] = useState(countdown(dateEnd));

    useEffect(() => {
        const id = setTimeout(() => {
        setTimeTo(countdown(dateEnd));
        }, 1000);

        return () => {
        clearTimeout(id);
        };
    });

    return <span>{ timeTo }</span>;
};

const AuctionStatus = ({ auction }) => {
    const status = getStatus(auction);
    const { variant, text: statusText } = statusConfig[status]
    // todo: timers?
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.status} ${styles[variant]}`}>
                {statusText}
            </div>
            <div className={`${styles.times}`}>
                <Label text="Ends" />
                <div className={`${styles.timeContent}`}>{formatDate(auction['date_end'])}</div>
            </div>
            <div className={`${styles.times}`}>
                <Label text="Time remaining" />

                <div className={`${styles.timeContent}`}><TimeRemaining to={auction.date_end} /></div>
            </div>
        </div>
    )
}

export default AuctionStatus
