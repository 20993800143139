import React from 'react'

const Icon2 = () => {
    return (
        <svg
            width="56"
            height="57"
            viewBox="0 0 56 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="0.792969" width="56" height="56" rx="28" fill="#1E2737" />
            <path
                d="M28.2134 29.504C29.9684 27.992 31.4264 26.534 31.4264 24.482C31.4264 22.106 29.8064 20.756 27.2954 20.756C24.8654 20.756 23.2454 22.376 23.2454 24.968V25.319H20.9774V24.833C20.9774 21.269 23.4884 18.731 27.2954 18.731C31.3724 18.731 33.7484 20.972 33.7484 24.374C33.7484 27.209 31.9124 28.991 29.6714 30.908L24.0014 35.714H33.8834V37.793H20.8694V35.876L28.2134 29.504Z"
                fill="#FF7F47"
            />
        </svg>
    )
}

export default Icon2
