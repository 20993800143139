import React, { useEffect } from 'react'
import styles from '../account-created/style.module.css'
import Title from 'src/components/modal/modals-components/title'
import { useModal } from 'src/lib/providers/modal'
import { t } from 'src/lib/i18n'
import { Link } from 'react-router-dom'
import { MODAL } from 'src/components/layouts/Layout'
import { useActivate } from 'src/lib/hooks/auth'

// eslint-disable-next-line react/prop-types
const AccountActivation = () => {
    const { closeModal, openModal } = useModal();
    const { activate, isLoading, isActivated, error } = useActivate();
    const errorMessage = error ? "The confirmation link has been used or expired." : "";

    useEffect(() => {
      activate();
    }, []);

    if (isActivated) return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperCreated}>
                <Title
                    handleCloseModal={closeModal}
                    title={"Your account has been activated!"}
                />
            </div>
            <div className={styles.modalFormBox}>
                <Link className={styles.link} onClick={() => openModal(MODAL.LOGIN)}>
                    {t('Login')}
                </Link>
            </div>
        </div>
    )

    return <div className={styles.wrapper}>
        <div className={styles.wrapperCreated}>
            <Title
                handleCloseModal={closeModal}
                title={isLoading ? "Activating your account.." : errorMessage }
            />
        </div>
        <div className={styles.modalFormBox}>
            <Link className={styles.link} onClick={() => openModal(MODAL.LOGIN)}>
                {t('Login')}
            </Link>
        </div>
    </div>;
}

export default AccountActivation
