import styles from './style.module.css'

const InnerPageWrapper = ({ darkerBackground, className = '', children }) => {
    return (
        <div
            className={`${styles.wrapper} ${darkerBackground ? styles.darker : ''} ${className}`}>
            {children}
        </div>
    )
}

export default InnerPageWrapper
