import React, { useState } from 'react'
import styles from './style.module.css'
function TestModal() {
    const [isOpen, setIsOpen] = useState(false)

    const toggleModal = () => setIsOpen(!isOpen)

    return (
        <>
            <button onClick={toggleModal}>Toggle Modal</button>
            <div
                className={`${styles.modalOverlay} ${isOpen ? styles.show : ''}`}>
                <div className={styles.modalContent}>This is a modal</div>
            </div>
        </>
    )
}

export default TestModal
