import styles from './style.module.css'
import Label from 'src/components/label'

const LotInfoRow = ({ label, title, onClick }) => {
    return (
        <div
            className={styles.row}
            onClick={onClick}>
            <div>
                <Label text={label} />
                <div>{title}</div>
            </div>
            <div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect y="12" width="24" height="1" fill="black" />
                    <rect
                        x="11.5"
                        y="24"
                        width="24"
                        height="1"
                        transform="rotate(-90 11.5 24)"
                        fill="black"
                    />
                </svg>
            </div>
        </div>
    )
}

export default LotInfoRow
