import styles from './style.module.css'
import { Accordion, Card, useAccordionButton } from 'react-bootstrap'
import Label from 'src/components/label'
import { useState } from 'react'

function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
        handleClick()
    })

    return (
        <div className={styles.toggleHeader} onClick={decoratedOnClick}>
            {children}
        </div>
    )
}

const PlusIcon = () => (
    <div className={styles.toggleIcon}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="12" width="24" height="1" fill="black" />
            <rect
                x="11.5"
                y="24"
                width="24"
                height="1"
                transform="rotate(-90 11.5 24)"
                fill="black"
            />
        </svg>
    </div>
)

const MinusIcon = () => (
    <div className={styles.toggleIcon}>
        <svg
            width="24"
            height="1"
            viewBox="0 0 24 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="1" fill="black" />
        </svg>
    </div>
)

const FaqAccordion = ({ data = [] }) => {
    const [activeKey, setActiveKey] = useState(null)
    return (
        <Accordion defaultActiveKey="0">
            {data.map((item, index) => {
                return (
                    <Card
                        key={index}
                        className={`${styles.toggleCard} ${activeKey === index ? styles.active : ''}`}>
                        <CustomToggle
                            as={Card.Header}
                            eventKey={index}
                            handleClick={() => {
                                if (activeKey === index) {
                                    setActiveKey(null)
                                } else {
                                    setActiveKey(index)
                                }
                            }}>
                            <div>
                                <Label text={`Question ${index + 1}`} />
                                <div>{item.q}</div>
                            </div>
                            {activeKey === index ? <MinusIcon /> : <PlusIcon />}
                        </CustomToggle>
                        <Accordion.Collapse eventKey={index}>
                            <Card.Body className={styles.toggleBody}>
                                {item.a}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            })}
        </Accordion>
    )
}

export default FaqAccordion
