import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import AuctionList from 'src/components/auction/action-list'
import { useEffect, useState } from 'react'
import TabsComponent from 'src/components/tabs'
import UserHero from 'src/components/user-profile-hero'
import BidList from 'src/components/bidList'
import OwnedList from 'src/components/owned-list'
import ImpactList from 'src/components/impact-card-list'

export default function Profile() {
    const [activeTab, setActiveTab] = useState('watchlist')
    const [isDefinedTab, setIsDefinedTab] = useState(false)
    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const tab = params.get('tab') || 'watchlist'
        setActiveTab(tab)
    }, [location.search])

    const tabs = [
        {
            name: 'Watchlist',
            key: 'watchlist',
        },
        {
            name: 'My bids',
            key: 'myBids',
        },
        {
            name: 'Owned',
            key: 'owned',
        },
        {
            name: 'My impact',
            key: 'myImpact',
        },
    ]
    return (
        <div>
            <UserHero />

            <Container
                style={{
                    paddingBottom: '24px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                }}>
                <TabsComponent
                    tabs={tabs}
                    active={activeTab}
                    style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                        marginBottom: '40px',
                    }}
                />
                {isDefinedTab && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '50px',
                            textAlign: 'center',
                        }}>
                        <h4
                            style={{
                                fontSize: '36px',
                                lineHeight: '41px',
                                marginBottom: '8px',
                                textAlign: 'center',
                            }}>
                            You currently don't have any items on your
                            watchlist.
                        </h4>
                        Start exploring our auctions to add lots and stay
                        updated on your favorites.
                    </div>
                )}
                <div className="position-relative">
                    {activeTab === 'watchlist' && (
                        <AuctionList type={'watchlist'} />
                    )}

                    {activeTab === 'myBids' && <BidList type={'myBids'} />}

                    {activeTab === 'owned' && <OwnedList type={'owned'} />}
                    {activeTab === 'myImpact' && (
                        <ImpactList type={'myImpact'} />
                    )}

                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        }}></div>
                </div>
            </Container>
        </div>
    )
}
