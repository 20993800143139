import styles from './style.module.css'
import { useModal } from 'src/lib/providers/modal'
import Label from '../../label'
import ScrollFade from '../../scroll-fade'

export const DigitalIdModal = () => {
    const { closeModal, context } = useModal()

    const sampleData = {
        type: 'Tennis racket',
        brand: 'Donnay',
        model: 'Borg Pro',
        prop2: 'prop value',
        prop3: 'prop value',
        prop4: 'prop value',
        prop5: 'prop value',
        prop6: 'prop value',
        prop7: 'prop value',
        prop8: 'prop long some long very long value',
        prop9: 'prop value',
        prop11: 'prop value',
        prop12: 'prop value',
        prop13: 'prop value',
        prop14: 'prop value',
        prop15: 'prop value',
        prop16: 'prop value',
        prop17: 'prop value',
    }
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.closeIconBox} onClick={closeModal}>
                    <div onClick={closeModal} className={styles.closeIcon}>
                        <img src="/close-icon-black.svg" alt="Close" />
                    </div>
                </div>
                <div className={styles.titleImageBox}>
                    <h3>Digital ID</h3>
                    {context?.cover_image && (
                        <div className={styles.image}>
                            <img
                                src={context?.cover_image}
                                alt={context?.title}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.content}>
                    <ScrollFade />
                    <div className={styles.contentTitle}>Item details</div>
                    <div className={styles.contentRow}>
                        <Label text={'Condition'} />
                        <div>
                            Pre-owned: An item that has been used previously.
                        </div>
                    </div>
                    <div className={styles.propsGrid}>
                        {Object.keys(sampleData).map(key => {
                            return (
                                <div key={key} className={styles.contentRow}>
                                    <Label text={key} />
                                    <div>{sampleData[key]}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalIdModal
