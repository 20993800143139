import { api } from "../api/client"
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../providers/auth";
import useLocalStorage from "./localStorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APP_URL } from "src/config";

const GOOGLE_REDIRECT = `${APP_URL}/?auth=google`;

export const useAutologin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { refresh, setAccess, setUser, setIsLoggedIn, logout } = useAuth();
  const { navigate } = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // setError(null);
      setIsLoading(true);

      try {
        const { access } = await api.post("/auth/jwt/refresh", { refresh });
        setAccess(access);
        const userData = await api.get('/users/me/', {
          headers: {
            'Authorization': `Bearer ${access}`
          }
        });
        setUser(userData);
        setIsLoggedIn(true);
      } catch (error) {
        console.log("ERR");
        logout();
        navigate("/?modal=login")
        // setError(error);
      }

      setIsLoading(false);
    };

    if (refresh) fetchData();
    else setIsLoading(false);
  }, []);

  return { isLoading };
}

export const useActivate = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const uid = searchParams.get('uid');
  const isLinkOk = token && uid;
  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [error, setError] = useState(isLinkOk ? null : { message: 'Activation link is broken.' });

  async function activate() {
    setError(null);
    setIsLoading(true);

    try {
      await api.post('/users/activation/', { uid, token });
      setIsActivated(true);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return { activate, error, isLoading, isActivated };
}

// export const useRefresh = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [refresh] = useLocalStorage('refresh', null);
//   const [, setAccess] = useLocalStorage('access', null);
//   const [error, setError] = useState(null);

//   const request = (refresh) => api.post("/auth/jwt/refresh", { refresh });

//   useEffect(() => {
//     const fetchData = async () => {
//       setError(null);
//       setIsLoading(true);

//       try {
//         const { access } = await request(refresh);
//         setAccess(access);
//       } catch (error) {
//         setError(error);
//       }

//       setIsLoading(false);
//     };

//     if (refresh) fetchData();
//     else setIsLoading(false);
//   }, [refresh]);

//   return { isLoading, error };
// }

export const useLogout = () => {
  const { logout } = useAuth();

  return { logout };
}

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setIsLoggedIn, setUser, setAccess, setRefresh } = useAuth();

  const request = (data) => api.post("/auth/jwt/create/", data);

  async function getUser(access) {
    const userData = await api.get('/users/me/', {
      headers: {
        'Authorization': `Bearer ${access}`
      }
    });

    setUser(userData);
  }

  async function login({ email, password }) {
    setError(null);
    setIsLoading(true);

    try {
      const { access, refresh } = await request({ email, password });
      setAccess(access);
      setRefresh(refresh);
      await getUser(access);
      setIsLoggedIn(true);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return { login, isLoading, error };
};

export const useRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [error, setError] = useState(null);

  const request = (data) => api.post("/users/", data);

  async function register({ email, password }) {
    setError(null);
    setIsLoading(true);

    try {
      const { email: registeredEmail } = await request({ email, password });
      setIsRegistered(true);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return { register, isLoading, isRegistered, error };
};

export const usePasswordRecover = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRecovered, setIsRecovered] = useState(false);
  const [error, setError] = useState(null);

  const request = (data) => api.post('/users/reset_password/', data);

  async function recover({ email }) {
    setError(null);
    setIsLoading(true);

    try {
      await request({ email });
      setIsRecovered(true);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return { recover, isLoading, isRecovered, error };
}

export const usePasswordReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const uid = searchParams.get('uid');
  const isLinkOk = token && uid;
  const [isLoading, setIsLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [error, setError] = useState(isLinkOk ? null : { message: 'Reset link is broken.' });

  const request = (data) => api.post('/users/reset_password_confirm/', data);

  async function reset({ password }) {
    setError(null);
    setIsLoading(true);

    try {
      await request({ uid, token, new_password: password });
      setIsLoading(false);
      searchParams.delete('uid');
      searchParams.delete('token');
      setSearchParams(searchParams);
      setIsReset(true);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }

  return { reset, isLoading, isReset, error };
}

export const useGoogleLoginLink = () => {
  const [isLoading, setIsLoading] = useState(false);
  // https://memora.markinthedark.com/api/v1/auth/o/google-oauth2/?redirect_uri=https://memora.mysu.pro/?auth=google
  const [googleURL, setGoogleURL] = useState('');

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const { authorization_url } = await api.get("/auth/o/google-oauth2/", { params: { redirect_uri: GOOGLE_REDIRECT } });
        setGoogleURL(authorization_url);
        setIsLoading(false);
      } catch (error) {
        // setError(error);
      }

      setIsLoading(false);
    };

    if (!googleURL) fetchData();
  }, [googleURL]);

  return { googleURL, isLoading };
}

export const useGoogleLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setIsLoggedIn, setUser, setAccess, setRefresh, isLoggedIn } = useAuth();

  // const request = (data) => api.post("/auth/o/google-oauth2/", data);

  async function getUser(access) {
    const userData = await api.get('/users/me/', {
      headers: {
        'Authorization': `Bearer ${access}`
      }
    });

    setUser(userData);
  }

  const loginGoogle = useCallback(({ state, code, scope, authuser, prompt }) => {
    async function login() {
      setError(null);
      setIsLoading(true);

      try {
        const { access, refresh } = await api.post("/auth/o/google-oauth2/",
          {},
          // { state, code, scope, authuser, prompt }, // { state },
          { params: { state, code, scope, authuser, prompt } }
        );
        setAccess(access);
        setRefresh(refresh);
        await getUser(access);
        setIsLoggedIn(true);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    }

    if (!isLoading && !isLoggedIn) login();
  }, [isLoading, isLoggedIn])

  // async function loginGoogle({ state, code, scope, authuser, prompt }) {
  //   setError(null);
  //   setIsLoading(true);

  //   try {
  //     const { access, refresh } = await api.post("/auth/o/google-oauth2/",
  //       { state, code, scope, authuser, prompt }, // { state },
  //       { params: { state, code, scope, authuser, prompt } }
  //     );
  //     setAccess(access);
  //     setRefresh(refresh);
  //     await getUser(access);
  //     setIsLoggedIn(true);
  //   } catch (error) {
  //     setError(error);
  //   }

  //   setIsLoading(false);
  // }


  return { loginGoogle, isLoading, error };
}