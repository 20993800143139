import React from 'react'
import styles from './style.module.css'

import Button from '../button'

const ImpactCard = () => {
    return (
        <div className={styles.card}>
            <div className={styles.titleImageWrapper}>
                {' '}
                <div className={styles.charityInfo}>
                    <h5 className={styles.charityLabel}>THE CHARITY</h5>
                    <h2 className={styles.charityName}>
                        Americas for the arts
                    </h2>
                    <p className={styles.charityDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna.
                    </p>
                </div>
                <div className={styles.imageWrapper}>
                    {' '}
                    <img
                        src="https://get.wallhere.com/photo/animals-fantasy-art-nature-humor-giraffes-wildlife-giraffe-fauna-giraffidae-savanna-1698x1200-px-727991.jpg"
                        alt="Racket"
                        className={styles.racketImage}
                    />
                </div>
            </div>
            <div className={styles.donationBox}>
                <div className={styles.donationInfo}>
                    <h5 className={styles.donationLabel}>YOUR DONATION</h5>
                    <h1 className={styles.donationAmount}>$10,000</h1>
                </div>
                <div className={styles.buttons}>
                    <Button variant="neutral-dark" fullWidth={false}>
                        Charity details
                    </Button>
                    <Button variant="neutral-dark" fullWidth={false}>
                        Tax documents
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ImpactCard
