import React from 'react'
import styles from './style.module.css'
import Title from 'src/components/modal/modals-components/title'
import BackIcon from 'src/assets/images/backIcon'
import SignContent from '../sign/sign-content'
import CustomForm from 'src/components/modal/modals-components/form'
import { t } from 'src/lib/i18n'
import Form from 'react-bootstrap/Form'
import formStyles from '../modals-components/form/style.module.css'
import signContentStyles from '../sign/sign-content/style.module.css'
import { useLogin, usePasswordReset } from 'src/lib/hooks/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'src/lib/providers/auth'
import { useModal } from 'src/lib/providers/modal'
import SuccessReset from '../success-reset'
import { MODAL } from 'src/components/layouts/Layout'

const NewPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    passwordRepeat: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match',
    ),
})

// eslint-disable-next-line react/prop-types
const NewPassword = ({ handleCloseModal }) => {
    const { closeModal, openModal } = useModal()
    const { reset, isReset, error, isLoading } = usePasswordReset()
    const [searchParams] = useSearchParams()
    const redirectTo =
        searchParams.get('redirect_to') || window.location.pathname

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordRepeat: '',
        },
        validationSchema: NewPasswordSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => reset(values),
    })

    if (isReset) return <SuccessReset />

    return (
        <div className={styles.NewPassWrapper}>
            <Form onSubmit={formik.handleSubmit}>
                <div className={styles.newPassword}>
                    <Title
                        handleCloseModal={handleCloseModal}
                        title="New password">
                        <BackIcon onClick={handleCloseModal} />
                    </Title>
                    <div className={styles.modalFormBox}>
                        <Form.Control
                            type="password"
                            placeholder={t('Password')}
                            className={formStyles.modalInput}
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.password}
                        <Form.Control
                            type="password"
                            placeholder={t('Password')}
                            className={formStyles.modalInput}
                            name="passwordRepeat"
                            value={formik.values.passwordRepeat}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.passwordRepeat}
                    </div>
                </div>
                <div className={styles.modalFormBox}>
                    <div className={signContentStyles.forgotWrapper}>
                        <button type="submit" className={signContentStyles.btn}>
                            {t('Reset password')}
                        </button>
                    </div>
                    <div className={signContentStyles.steps}>STEP 3 of 4</div>
                </div>
            </Form>
        </div>
    )
}

export default NewPassword
