const ArrowPrev = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="18"
                cy="18"
                r="18"
                transform="matrix(-1 0 0 1 36 0)"
                fill="black"
            />
            <g clipPath="url(#clip0_215_11835)">
                <path
                    d="M26.4844 18.0005H9.51381M9.51381 18.0005L17.6243 9.89003M9.51381 18.0005L17.6243 26.1109"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M26.4844 18.0005H9.51381M9.51381 18.0005L17.6243 9.89003M9.51381 18.0005L17.6243 26.1109"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M26.4844 18.0005H9.51381M9.51381 18.0005L17.6243 9.89003M9.51381 18.0005L17.6243 26.1109"
                    stroke="white"
                    strokeWidth="1.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_215_11835">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="matrix(-1 0 0 1 27 9)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowPrev
