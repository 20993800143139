/* eslint-disable react/prop-types */
import React from 'react'
import styles from './style.module.css'
import Icon1 from 'src/assets/images/icon1'
import Icon2 from 'src/assets/images/icon2'

const CreatedAccountContent = ({ email = 'eliza@memora.com.' }) => {
    return (
        <div className={styles.contentWrapper}>
            <div className={styles.itemBlock}>
                <div className={styles.icon}>
                    <Icon1 />
                </div>
                <div>
                    We've just sent an email to {email}
                    <br /> Please verify your email by clicking the link in the
                    message.
                </div>
            </div>
            <div className={styles.itemBlock}>
                <div className={styles.icon}>
                    <Icon2 />
                </div>
                <div>
                    In order to start placing bids, we need to complete your
                    profile, and verify your identity. You can skip this for now
                    and browse our auctions, but you won’t be able to place
                    bids.
                </div>
            </div>
        </div>
    )
}

export default CreatedAccountContent
