import styles from './style.module.css'
const LotInfo = ({ lot, onlyImage }) => {
    return (
        <>
            <div className={styles.imageContainer}>
                <img src={lot?.cover_media?.media_file} />
            </div>
            {!onlyImage && <div className={styles.title}>{lot?.title}</div>}
        </>
    )
}

export default LotInfo
