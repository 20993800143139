import React from 'react'
import styles from './style.module.css'
import SignContent from 'src/components/modal/sign/sign-content'
import BackIcon from 'src/assets/images/backIcon'
import Title from 'src/components/modal/modals-components/title'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'

// eslint-disable-next-line react/prop-types
const SuccessReset = () => {
    const { closeModal, openModal } = useModal();

    return (
        <>
            <div className={styles.success}>
                <Title
                    handleCloseModal={closeModal}
                    title="Password successfully reset"
                    subTitle="Your password has been reset. You can now login to your
                        account using your new password.">
                    <BackIcon onClick={closeModal} />
                </Title>
            </div>
            <div className={styles.modalFormBox}>
                <SignContent step="4" buttonText="Login" buttonAction={openModal(MODAL.LOGIN)} />
            </div>
        </>
    )
}

export default SuccessReset
