import React from 'react'
import styles from './style.module.css'
import SidebarMenu from 'react-bootstrap-sidebar-menu'
import { SidebarMenuFooter } from 'react-bootstrap-sidebar-menu'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import BackIcon from 'src/assets/images/backIcon'
const menuItems = [
    {
        title: 'Profile information',
        state: 'active',
    },
    {
        title: 'Password',
        state: 'inactive',
    },
    {
        title: 'Delivery address',
        state: 'inactive',
    },
    {
        title: 'Payment',
        state: 'inactive',
    },
    {
        title: 'Email notifications',
        state: 'inactive',
    },
]
const UserProfileLayout = ({ children, handleBack }) => {
    return (
        <div>
            <div style={{ display: 'flex', minHeight: '100vh' }}>
                {/* <Navbar expand="xxl" className={styles.nav}>
                    <Container fluid>
                        <Navbar
                            id="basic-navbar-nav"
                            style={{
                                width: '100%',
                            }}>
                            <Nav className={styles.navBox}>
                                <Nav.Link href="/" className={styles.home}>
                                    Home
                                </Nav.Link>
                                <Navbar.Brand>
                                    <img
                                        src="/Memora_Logo.png"
                                        className={styles.brandImage}
                                    />
                                </Navbar.Brand>
                                <Nav.Item className={styles.navPhoto}>
                                    <img src="/little_Mona.png" />
                                </Nav.Item>
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar> */}
                <SidebarMenu className={styles['sidebar-menu']}>
                    <SidebarMenu.Header className={styles['header']}>
                        <SidebarMenu.Brand className={styles.title} />
                        {/* <SidebarMenu.Nav.Link
                            href="/"
                            className={styles.back}
                        /> */}
                    </SidebarMenu.Header>
                    <SidebarMenu.Body className={styles.sidebarbody}>
                        <SidebarMenu.Sub>
                            <SidebarMenu.Nav>
                                <SidebarMenu.Nav.Link
                                    href="/"
                                    className={styles.back}
                                />
                                {menuItems.map((item, index) => (
                                    <SidebarMenu.Nav.Item
                                        className={styles.stage}
                                        key={index}>
                                        <SidebarMenu.Sub
                                            as="div"
                                            className={`${styles['icon-stage']} ${
                                                item.state === 'active' &&
                                                styles.active
                                            }`}
                                        />
                                        <SidebarMenu.Nav.Title
                                            className={`${styles.stageTitle} ${
                                                item.state === 'active' &&
                                                styles.active
                                            }`}>
                                            {item.title}
                                        </SidebarMenu.Nav.Title>
                                    </SidebarMenu.Nav.Item>
                                ))}
                            </SidebarMenu.Nav>
                        </SidebarMenu.Sub>
                    </SidebarMenu.Body>
                    <SidebarMenuFooter>
                        <SidebarMenu.Nav.Link className={styles.footer}>
                            Delete account
                        </SidebarMenu.Nav.Link>
                    </SidebarMenuFooter>
                </SidebarMenu>
                {children}
            </div>
        </div>
    )
}

export default UserProfileLayout
