import styles from './style.module.css'
import Form from 'react-bootstrap/Form'

const Checkbox = ({ label = null, onChange, disabled, name, value }) => {
    return (
        <div
            className={`${styles.checkboxWrapper} ${disabled ? styles.disabled : ''}`}>
            {label && <span>{label}</span>}

            <Form.Check
                onChange={onChange}
                className={styles.test}
                type="checkbox"
                id={`default-checkbox`}
                name={name}
                value={value}
            />
        </div>
    )
}

export default Checkbox
