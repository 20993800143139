import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './style.module.css'
import Button from 'src/components/button'
import userPhoto from 'src/assets/images/mona_profile.png'
import notPhoto from 'src/assets/images/Group9.png'

const userImage = userPhoto

const UserHero = () => {
    return (
        <div className={`${styles.heroUserSection}`}>
            <Container>
                <Row>
                    <Col>
                        <div className={`${styles.titleImageBox}`}>
                            <h1>Welcome back, Mona</h1>
                            <div
                                style={{
                                    width: '212px',
                                    height: '212px',
                                    borderRadius: '50%',
                                    // backgroundImage: `url(${userImage ? userImage : notPhoto})`,//рабочий вариант, когда решится вопрос с корректной загрузкой картинок
                                    backgroundImage: `url(https://i.pinimg.com/736x/3f/f1/20/3ff120824122616c4922797749f58fba.jpg)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#EDE9E3',
                                    backgroundSize: userImage
                                        ? 'cover'
                                        : 'auto',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                }}>
                                <div className={`${styles.verified}`}></div>
                                <div className={`${styles.imageButton}`}>
                                    <button className={styles.btnGrey}>
                                        Edit profile
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.verifyText}>
                            <h4
                                style={{
                                    fontSize: '36px',
                                    lineHeight: '41px',
                                    marginBottom: '8px',
                                }}>
                                Not verified
                            </h4>
                            <p>
                                Complete your profile to start the verification
                            </p>

                            <p> process and begin placing bids.</p>
                            <Button
                                variant="primary"
                                style={{ marginTop: '16px' }}
                                fullWidth={false}>
                                Start verification
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserHero
