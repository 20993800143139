import React from 'react'
import OwnedCard from '../user-owned-card'

const cardsArray = new Array(3).fill(null)

const OwnedList = () => {
    return (
        <>
            {cardsArray.map((el, i) => (
                <OwnedCard key={i} isHighestBidder={Math.random() >= 0.5} />
            ))}
        </>
    )
}

export default OwnedList
