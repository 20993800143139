import React from 'react'
import styles from './style.module.css'
import Button from '../button'

import tennisImage from 'src/assets/images/tennis.jpg'
const BidCard = ({
    image,
    subTitle = 'Legends of Sport Björn Borg x Muhammad Ali',
    title = 'Björn Borg’s 1978 Wimbledon Champion Racket',
    bidAmount = '£8,800',
    isHighestBidder = false,
}) => {
    const statusMessage = isHighestBidder
        ? 'You are the highest bidder'
        : 'You have been outbid'
    return (
        <div className={styles.card}>
            <div className={styles.cardImage}>
                {' '}
                <img
                    src={image || tennisImage}
                    alt={title}
                    className={styles.image}
                />
            </div>

            <div className={styles.content}>
                <div className={styles.imageContent}>
                    {' '}
                    <div className={styles.subTitle}>{subTitle}</div>
                    <div className={styles.title}>{title}</div>
                </div>

                <div className={styles.bid}>
                    {' '}
                    <div className={styles.bidInfo}>
                        <div className={styles.bidAmountBox}>
                            <p> YOUR BID</p>

                            <div className={styles.bidAmount}>{bidAmount}</div>
                        </div>
                        <div className={styles.divider}></div>
                        <div
                            className={`${styles.highestBidder} ${isHighestBidder ? styles.highest : styles.outBid}`}>
                            {statusMessage}
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant="dark"
                            style={{ color: '#ff7f47', whiteSpace: 'nowrap' }}>
                            View all bids
                        </Button>

                        <Button variant="primary" style={{ height: '51px' }}>
                            Item details
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BidCard
