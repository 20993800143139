import styles from './style.module.css'
const BackButton = ({ onClick }) => {
    return (
        <div className={styles.button} onClick={onClick}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_215_13038)">
                    <path
                        d="M23.3125 12H0.685083M0.685083 12L11.499 1.18606M0.685083 12L11.499 22.8139"
                        stroke="#FF7F47"
                        strokeWidth="2"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_215_13038">
                        <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="matrix(-1 0 0 1 24 0)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}

export default BackButton
