import styles from './style.module.css'
import BackButton from '../back-button'
import BidStatus from '../bid-status'
import React from 'react'
import { IS_MOBILE, MOBILE_BREAKPOINT } from 'src/config'
import LotInfo from '../lot-info'

const BackHeader = ({ title, action, lot }) => {
    return (
        <div className={styles.head}>
            <BackButton onClick={action} />
            {IS_MOBILE && lot && <LotInfo lot={lot} onlyImage />}
            <div className={styles.heading}>{title}</div>
            {IS_MOBILE && <BidStatus />}
        </div>
    )
}

export default BackHeader
