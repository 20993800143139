import { api } from "../api/client"
import { useState, useEffect } from "react";

export const useAuctionsList = ({ limit=10, offset=0, ordering=""} = {}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);

  const request = (params) => api.get("/auctions/", {
    params
  });

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request({ limit, offset, ordering });
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [limit, offset, ordering]);

  return [{ items: data?.results || [], isLoading, error }];
}

export const useAuction = (uuid) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);

  const request = (uuid) => api.get(`/auctions/${uuid}/`);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await request(uuid);
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [uuid]);

  return [{ item: data, isLoading, error }];
}