import React from 'react'
import { Link } from 'react-router-dom'
import styles from './style.module.css'
const SocialIcons = ({ googleURL, facebookURL, appleURL }) => {
    return (
        <div className={styles.socialIcons}>
            { googleURL &&
                <div>
                    <a className={styles.linkIcon} href={googleURL}>
                        <img src="/google.svg" alt="google icon" />
                    </a>
                </div>
            }
            { facebookURL &&
            <div>
                <Link className={styles.linkIcon} href="#">
                    <img src="/facebook.svg" alt="facebook icon" />
                </Link>
            </div>
            }
            { appleURL &&
                <div>
                    <Link className={styles.linkIcon} href="#">
                        <img src="/apple.svg" alt="apple icon" />
                    </Link>
                </div>
            }
        </div>
    )
}

export default SocialIcons
