import { Col, Container, Row } from 'react-bootstrap'
import FaqAccordion from 'src/components/faq-accordion'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function Faq() {
    const data = [
        {
            q: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
            a: 'Convallis convallis tellus id interdum velit laoreet. Luctus venenatis lectus magna fringilla. Est ultricies integer quis auctor elit. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Eu ultrices vitae auctor eu augue ut. Quam nulla porttitor massa id neque aliquam vestibulum morbi.',
        },
        {
            q: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
            a: 'Convallis convallis tellus id interdum velit laoreet. Luctus venenatis lectus magna fringilla. Est ultricies integer quis auctor elit. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Eu ultrices vitae auctor eu augue ut. Quam nulla porttitor massa id neque aliquam vestibulum morbi.',
        },
        {
            q: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
            a: 'Convallis convallis tellus id interdum velit laoreet. Luctus venenatis lectus magna fringilla. Est ultricies integer quis auctor elit. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Eu ultrices vitae auctor eu augue ut. Quam nulla porttitor massa id neque aliquam vestibulum morbi.',
        },
        {
            q: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
            a: 'Convallis convallis tellus id interdum velit laoreet. Luctus venenatis lectus magna fringilla. Est ultricies integer quis auctor elit. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Eu ultrices vitae auctor eu augue ut. Quam nulla porttitor massa id neque aliquam vestibulum morbi.',
        },
        {
            q: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?',
            a: 'Convallis convallis tellus id interdum velit laoreet. Luctus venenatis lectus magna fringilla. Est ultricies integer quis auctor elit. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Eu ultrices vitae auctor eu augue ut. Quam nulla porttitor massa id neque aliquam vestibulum morbi.',
        },
    ]

    return (
        <InnerPageWrapper darkerBackground>
            <Container>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <h1>Frequently Asked Questions</h1>
                    </Col>
                    <Col xs={12} lg={7} xl={8} className="mt-4 mt-lg-0">
                        <FaqAccordion data={data} />
                    </Col>
                </Row>
            </Container>
        </InnerPageWrapper>
    )
}
