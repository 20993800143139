import React, { useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import metamaskIcon from 'src/assets/images/metam3.jpg'
import BackIcon from 'src/assets/images/backIcon'
import Button from 'src/components/button'

const ProfileInformationPage = () => {
    const [hasPhoto, setHasPhoto] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('')

    const countries = [
        'Russia',
        'China',
        'Canada',
        'Germany',
        'France',
        'Japan',
        'United Kingdom',
        'United States',
        'Russia',
        'China',
        'Canada',
        'Germany',
        'France',
        'Japan',
        'United Kingdom',
        'United States',
    ]
    const photoUrl = metamaskIcon
    const handlePhotoUpload = () => {
        setHasPhoto(true)
    }

    const handleCountrySelect = country => {
        setSelectedCountry(country)
    }
    return (
        <>
            <div className={styles.informationUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Profile information</h4>

                        <div className={styles.uploadPhotoBox}>
                            <div
                                className={styles.photo}
                                style={{
                                    backgroundColor: hasPhoto
                                        ? 'transparent'
                                        : 'rgba(212, 207, 199, 1)',
                                    backgroundImage: hasPhoto
                                        ? `url(${photoUrl})`
                                        : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                <p>Upload photo</p>
                                {!hasPhoto && (
                                    <div className={styles.photoIconMobile}>
                                        <img
                                            src="/emptyIcon.svg"
                                            alt="Upload Photo"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.uploadButtonBox}>
                                <button
                                    className={`${styles.btnUserPage} ${styles.btnUploadPhoto}`}
                                    onClick={handlePhotoUpload}>
                                    Upload new photo
                                </button>
                                <button
                                    className={`${styles.btnUserPage} ${styles.btnDelPhoto}`}
                                    onClick={handlePhotoUpload}>
                                    Delete photo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.formContainer}>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            USER INFORMATION
                        </p>
                        <Container className={styles.informationUserContainer}>
                            <Form style={{ minHeight: 'auto' }}>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            className={styles.userPageInput}
                                            name="email"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Username"
                                            className={styles.userPageInput}
                                            name="username"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="First name"
                                            className={styles.userPageInput}
                                            name="firstName"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last name"
                                            className={styles.userPageInput}
                                            name="lastName"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone number"
                                            className={styles.userPageInput}
                                            name="phoneNumber"
                                        />
                                    </Col>{' '}
                                </Row>
                                <Row>
                                    <Col>
                                        <Dropdown
                                            onSelect={handleCountrySelect}>
                                            <Dropdown.Toggle
                                                className={`${styles.userPageInput} ${styles.dropdownToggle} w-100 d-flex justify-content-between align-items-center`}
                                                id="tax-residency-country-dropdown">
                                                <span
                                                    className={`text-start ${!selectedCountry ? styles.inactiveText : ''}`}>
                                                    {selectedCountry ||
                                                        'Tax residency country'}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                className="w-100"
                                                style={{
                                                    maxHeight: '192px',
                                                    overflowY: 'auto',
                                                }}>
                                                {countries.map(
                                                    (country, index) => (
                                                        <Dropdown.Item
                                                            key={index}
                                                            eventKey={country}>
                                                            {country}
                                                        </Dropdown.Item>
                                                    ),
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <div className={styles.buttonBox}>
                                    <Button>Update profile</Button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileInformationPage
