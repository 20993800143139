/* eslint-disable react/prop-types */
import React from 'react'
import styles from './style.module.css'
const Title = ({
    handleCloseModal,
    children,
    title = '',
    subTitle = '',
    isEndPosition = false,
}) => {
    return (
        <>
            <span
                className={`${isEndPosition && styles.icon}`}
                onClick={handleCloseModal}>
                {children}
            </span>
            <h4>{title}</h4>
            {subTitle && <p>{subTitle}</p>}
        </>
    )
}

export default Title
