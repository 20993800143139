import React from 'react'
import styles from './style.module.css'
import Title from 'src/components/modal/modals-components/title'
import CreatedAccountContent from './account-created-content'
import { useModal } from 'src/lib/providers/modal'

// eslint-disable-next-line react/prop-types
const AccountCreated = ({ email }) => {
    const { closeModal } = useModal()

    return (
        <div className={styles.wrapperCreatedSection}>
            <div className={styles.wrapperCreated}>
                <Title
                    handleCloseModal={closeModal}
                    title="Your account has been created – welcome to Memora"
                />

                <CreatedAccountContent email={email} />
            </div>
            <div className={styles.modalFormBox}>
                <button
                    type="button"
                    className={`${styles.btn} ${styles.btnGrey}`}
                    onClick={closeModal}>
                    Skip for now
                </button>
                <button
                    type="button"
                    className={`${styles.btn} `}
                    onClick={closeModal}>
                    Complete profile
                </button>
            </div>
        </div>
    )
}

export default AccountCreated
